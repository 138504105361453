import { useContext } from 'react';
import { StyleContext } from 'utils/context/StyleContextProvider';

export default function CompareDetails({ currentBlock, index }) {
    const styles = useContext(StyleContext);

    return (
        <div>
            <p>
                {currentBlock.copy.split('\n').map((item, index) =>
                    index === 0
                        ? item
                        : [
                              // eslint-disable-next-line react/jsx-indent
                              <span className={styles.br} key={index} />,
                              item,
                          ],
                )}
            </p>
            <br />
            {currentBlock.list && (
                <ul>
                    {currentBlock.list.map((detail) => (
                        <li key={`${detail}-${index}`}>{detail}</li>
                    ))}
                </ul>
            )}
        </div>
    );
}
