import classNames from 'classnames';
import Image from '../Layout/Module/Image';
import Text from '../Layout/Module/Text';
import styles from './Person.module.scss';

export default function Person({ data, columns }) {
    const { headshot, name, role, bio } = data,
        isQuote = columns === 1;

    return (
        <div className={classNames(styles.person, isQuote && styles.quote)}>
            <Image
                data={headshot}
                options={{
                    width: isQuote ? 98 : 162,
                    quality: 100,
                    align: isQuote ? 'zero' : 'center',
                }}
            />
            <div className={styles.bio}>
                <h4 className={styles.name}>{name}</h4>
                {role && <p className={styles.role}>{role}</p>}
                <Text
                    data={{
                        body: bio,
                        style: 'Center',
                    }}
                />
            </div>
        </div>
    );
}
