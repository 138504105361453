import { useEffect, useState, useContext } from 'react';
import { StyleContext } from 'utils/context/StyleContextProvider';
import classNames from 'classnames';
import { getBackgroundFile, parseURL } from '@viome/vnxt-nucleus';
import { useViewport } from 'utils/context/ViewportContextProvider';
import Text from '../Text';
import FaqsItem from './FaqsItem';
import styles from './Card.module.scss';

export default function Card({ data, type = 'card' }) {
    const parentStyles = useContext(StyleContext) || {},
        {
            name,
            beforeBody,
            beforeBackgroundColor,
            beforeBackgroundImage,
            beforeButton,
            afterBody,
            afterBackgroundColor,
            afterBackgroundImage,
            afterButton,
        } = data,
        [active, setActive] = useState(false),
        onClick = () => {
            setActive(!active);
        },
        inlineStylesBefore = {},
        inlineStylesAfter = {},
        { pixelDensity, width } = useViewport(),
        [bgImage, setbgImage] = useState(null);

    let afterBgImage = null;

    useEffect(() => {
        if (beforeBackgroundImage) {
            const imageData = beforeBackgroundImage,
                imageFile = imageData?.fields?.file,
                parsedURL = getBackgroundFile(imageFile, pixelDensity, 'Small');

            if (parsedURL) {
                setbgImage(parsedURL);
            }
        }
        if (afterBackgroundImage) {
            const imageData = afterBackgroundImage,
                imageFile = imageData?.fields?.file,
                parsedURL = getBackgroundFile(imageFile, pixelDensity, 'Small');

            if (parsedURL) {
                afterBgImage = parseURL;
            }
        }
    }, [width]);

    if (beforeBackgroundColor) {
        inlineStylesBefore.backgroundColor = `#${beforeBackgroundColor}`;
    }
    if (afterBackgroundColor) {
        inlineStylesAfter.backgroundColor = `#${afterBackgroundColor}`;
    }

    if (bgImage) {
        // Set bg when client is ready
        inlineStylesBefore.backgroundImage = bgImage;
        inlineStylesAfter.backgroundImage = afterBgImage;
    } else if (beforeBackgroundImage) {
        const imageData = beforeBackgroundImage,
            imageFile = imageData?.fields?.file,
            parsedURL = getBackgroundFile(imageFile, 1, 'Small');

        if (parsedURL) {
            setbgImage(parsedURL);
        }
    }

    return (
        <div
            className={classNames(
                [styles[type], parentStyles[type]],
                type,
                active && styles.active,
            )}
            onClick={onClick}
            onKeyPress={onClick}
            role="button"
            tabIndex="0"
            aria-controls={name}
            aria-expanded={active}
        >
            {type === 'faqsItem' ? (
                <FaqsItem
                    name={name}
                    beforeBody={beforeBody}
                    afterBody={afterBody}
                    styles={{ styles, parentStyles }}
                    active={active}
                />
            ) : (
                <>
                    <div className={styles.front} style={inlineStylesBefore}>
                        <Text
                            data={{ body: beforeBody, style: 'Left Aligned' }}
                        />
                        {beforeButton && (
                            <img
                                src={beforeButton.fields.file.url}
                                alt="card open"
                            />
                        )}
                    </div>

                    <div className={styles.back} style={inlineStylesAfter}>
                        <Text
                            data={{ body: afterBody, style: 'Left Aligned' }}
                        />
                        {afterButton && (
                            <img
                                src={afterButton.fields.file.url}
                                alt="card close"
                            />
                        )}
                    </div>
                </>
            )}
        </div>
    );
}
