import { useContext } from 'react';
import classNames from 'classnames';
import { useViewport } from 'utils/context/ViewportContextProvider';
import { StyleContext } from 'utils/context/StyleContextProvider';
import { cBoolean, matchCSSModule, renderSection } from '@viome/vnxt-nucleus';
import SectionBody from './SectionBody';

export default function SectionBasic({
    data,
    additionalClasses,
    styles,
    sectionWrapper,
    moduleWrapper,
    children,
    isSubModule,
}) {
    const stylesContext = useContext(StyleContext) || styles || {},
        title = data?.title,
        sectionClass = data?.class,
        sectionID = data?.id,
        showTitle = cBoolean(data?.showTitle),
        reverse = cBoolean(data?.reverse),
        isFull = cBoolean(data?.isFull),
        marginTop = cBoolean(data?.marginTop),
        marginBottom = cBoolean(data?.marginBottom),
        parsedClasses = matchCSSModule(sectionClass, stylesContext),
        sectionAttributes = {},
        { width } = useViewport(),
        { renderAtMobile, renderAtTablet, renderAtDesktop } = data,
        renderConfig = { renderAtMobile, renderAtTablet, renderAtDesktop },
        showSection = renderSection(renderConfig, width);

    if (sectionID) {
        sectionAttributes.id = sectionID;
    }

    return (
        showSection && (
            <div
                className={classNames(
                    'viome__section__wrapper',
                    isFull && `full ${stylesContext?.full || ''}`,
                    reverse && `reverse ${stylesContext?.reverse || ''}`,
                    marginTop && `marginTop ${stylesContext?.marginTop || ''}`,
                    marginBottom &&
                        `marginBottom ${stylesContext?.marginBottom || ''}`,
                    sectionWrapper || (!cBoolean(isSubModule) && parsedClasses),
                    additionalClasses &&
                        matchCSSModule(additionalClasses, stylesContext),
                )}
                {...sectionAttributes}
            >
                {showTitle && <h4>{title}</h4>}
                {children ? (
                    <>{children}</>
                ) : (
                    <SectionBody
                        data={data}
                        sectionStyles={stylesContext}
                        moduleWrapper={moduleWrapper}
                    />
                )}
            </div>
        )
    );
}
