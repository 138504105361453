import React, { useState, useContext } from 'react';
import classNames from 'classnames';
import { DataContext } from 'utils/context/DataContextProvider';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import styles from './Careers.module.scss';

export default function CareersListings() {
    const { listings, filterOptions } = useContext(DataContext),
        reduceNBuild = (list) =>
            list.reduce((acc, curr) => {
                const { team, text } = curr,
                    keys = Object.keys(acc);
                if (text && text.length > 0) {
                    if (!keys.includes(team)) acc[team] = [curr];
                    else acc[team].push(curr);
                }
                return acc;
            }, {}),
        sortTeams = (items) =>
            items?.sort((a, b) => {
                if (a.team > b.team) {
                    return 1;
                }
                if (a.team < b.team) {
                    return -1;
                }
                return 0;
            }),
        [listingsToRender, setListingsToRender] = useState(
            reduceNBuild([...sortTeams(listings)]),
        ),
        [filterCriteria, setFilterCriteria] = useState([]),
        [location, setLocation] = useState(''),
        [team, setTeam] = useState(''),
        [commitment, setCommitment] = useState(''),
        addClassToOptions = (optionsList) =>
            optionsList.map((op) => ({
                ...op,
                className: styles.dropdown__option,
            })),
        locationOptions = addClassToOptions(filterOptions?.locations),
        teamOptions = addClassToOptions(filterOptions?.teams),
        commitementOptions = addClassToOptions(filterOptions?.commitments),
        checkByCriteria = (src, newCriteria) => {
            let res = true;
            for (let i = 0; i < newCriteria.length; i++) {
                const { criteria, value } = newCriteria[i];
                res = src[criteria] === value;
                if (!res) break;
            }
            return res;
        },
        toggleFilter = (criteria, val) => {
            const filterCriteriaObj = (f) => f.criteria !== criteria,
                criteriaFiltered = filterCriteria.filter(filterCriteriaObj),
                resultCriteria =
                    val === 'all'
                        ? [...criteriaFiltered]
                        : [...criteriaFiltered, { criteria, value: val }];
            setFilterCriteria(resultCriteria);
            const listingsFiltered = listings.filter((item) =>
                checkByCriteria(item, resultCriteria),
            );
            setListingsToRender(reduceNBuild(listingsFiltered));
        },
        handlersByFilter = {
            location: (criteria, val) => {
                setLocation(val);
                toggleFilter(criteria, val);
            },
            team: (criteria, val) => {
                setTeam(val);
                toggleFilter(criteria, val);
            },
            commitment: (criteria, val) => {
                setCommitment(val);
                toggleFilter(criteria, val);
            },
        },
        handleChange = (name, op) => handlersByFilter[name](name, op.value),
        generateListings = (list) =>
            list.map(
                ({
                    applyUrl,
                    commitment: mapCommitment,
                    hostedUrl,
                    id,
                    location: mapLocation,
                    team: mapTeam,
                    text,
                }) => (
                    <div
                        className={styles.posting}
                        data-qa-posting-id={id}
                        data-location={mapLocation}
                        data-team={mapTeam}
                        data-worktype={mapCommitment}
                        key={id}
                        id={id}
                    >
                        <div
                            className={styles.posting_apply}
                            data-qa="btn-apply"
                        >
                            <a
                                href={applyUrl}
                                className={styles.template_btn_submit}
                            >
                                Apply
                            </a>
                        </div>
                        <a className={styles.posting_title} href={hostedUrl}>
                            <h5 data-qa="posting_name">{text}</h5>
                            <div className={styles.posting_categories}>
                                <span
                                    href="#"
                                    className={classNames(
                                        styles.posting_category,
                                        styles.small_category_label,
                                    )}
                                >
                                    {mapLocation}
                                </span>
                            </div>
                        </a>
                    </div>
                ),
            );

    return (
        <section className={styles.listings}>
            <span className={styles.medium_utility_label}>Filter by:</span>
            <div className={styles.dropdown__container}>
                <Dropdown
                    menuClassName={styles.dropdown__options}
                    controlClassName={styles.dropdown__button}
                    arrowOpen={
                        <span
                            className={styles.dropdown__button_arrow_closed}
                        />
                    }
                    arrowClosed={
                        <span className={styles.dropdown__button_arrow} />
                    }
                    placeholderClassName={styles.dropdown__button_placeholderA}
                    options={locationOptions}
                    onChange={(val) => handleChange('location', val)}
                    value={location}
                    name="location"
                    placeholder="Location"
                />
            </div>
            <div className={styles.dropdown__container}>
                <Dropdown
                    menuClassName={styles.dropdown__options}
                    controlClassName={styles.dropdown__button}
                    arrowOpen={
                        <span
                            className={styles.dropdown__button_arrow_closed}
                        />
                    }
                    arrowClosed={
                        <span className={styles.dropdown__button_arrow} />
                    }
                    placeholderClassName={styles.dropdown__button_placeholderB}
                    options={teamOptions}
                    onChange={(val) => handleChange('team', val)}
                    value={team}
                    name="team"
                    placeholder="Team"
                />
            </div>
            <div className={styles.dropdown__container}>
                <Dropdown
                    menuClassName={styles.dropdown__options}
                    controlClassName={styles.dropdown__button}
                    arrowOpen={
                        <span
                            className={styles.dropdown__button_arrow_closed}
                        />
                    }
                    arrowClosed={
                        <span className={styles.dropdown__button_arrow} />
                    }
                    placeholderClassName={styles.dropdown__button_placeholderA}
                    options={commitementOptions}
                    onChange={(val) => handleChange('commitment', val)}
                    value={commitment}
                    name="commitment"
                    placeholder="Work Type"
                />
            </div>
            {listings.length > 0 &&
                Object.keys(listingsToRender).map((item) =>
                    item.index !== -1 ? (
                        <div key={`listing-${item}`}>
                            <div
                                className={classNames(
                                    styles.posting_category_title,
                                    styles.large_category_label,
                                )}
                            >
                                {item}
                            </div>
                            <div className={styles.horizontal_line} />
                            {listingsToRender[item]?.length &&
                                generateListings(listingsToRender[item])}
                        </div>
                    ) : null,
                )}
        </section>
    );
}
