import { createContext } from 'react';

export const DataContext = createContext();

/**
 * Context Provider for passing data to child components
 *
 * @param  {map}        styles      Data
 * @param  {function}   children    Function to render children
 * @return {function}
 */
const DataContextProvider = ({ data, children }) => (
    <DataContext.Provider value={data}>{children}</DataContext.Provider>
);
export default DataContextProvider;
