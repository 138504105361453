import StyleContextProvider from 'utils/context/StyleContextProvider';
import SectionBasic from './SectionBasic';
import styles from './HowItWorks.module.scss';

export default function Benefits({ data }) {
    return (
        <StyleContextProvider styles={styles}>
            <SectionBasic data={data} moduleWrapper={styles.steps} />
        </StyleContextProvider>
    );
}
