import React from 'react';
import StyleContextProvider from 'utils/context/StyleContextProvider';
import SectionBasic from 'containers/Sections/SectionBasic';
import styles from './PrecisionNutrientsCards.module.scss';

const PrecisionNutrientsCards = ({ data }) => (
    <StyleContextProvider styles={styles}>
        <SectionBasic data={data} />
    </StyleContextProvider>
);

export default PrecisionNutrientsCards;
