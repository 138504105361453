import React, { useContext } from 'react';
import { StyleContext } from 'utils/context/StyleContextProvider';
import classNames from 'classnames';
import { ENVIRONMENT } from 'utils/constants';
import { cBoolean, formatPrice } from '@viome/vnxt-nucleus';
import { PromoContext } from 'utils/context/PromoContextProvider';
import useShopify from 'utils/hooks/useShopify';
import Price from './Price';
import styles from './PriceWrapper.module.scss';

export default function PriceWrapper({
    data,
    product,
    isSubscription = false,
    pricingMode = 'Light',
    showTax = true,
    showDiscount = true,
    showPriceObservations = false,
}) {
    let price;

    const promoContext = useContext(PromoContext),
        discountValue = promoContext?.discountValue,
        showCurrency = cBoolean(data?.showCurrency),
        prefix = data?.prefix || '$',
        suffix = data?.suffix || (isSubscription ? '/month' : null),
        discount = data?.discount || discountValue || product?.discount,
        productShopify =
            (ENVIRONMENT === 'development' || ENVIRONMENT === 'preview'
                ? product?.shopifyStaging
                : product?.shopifyProduction) && useShopify(product),
        parentStyles = useContext(StyleContext) || {};

    if (ENVIRONMENT === 'production') {
        price = product?.price || 'N/A';
    } else {
        price = product?.priceStaging || 'N/A';
    }

    return (
        <div
            className={classNames(
                'priceWrapper',
                [styles.priceWrapper, parentStyles.priceWrapper],
                [styles[pricingMode], parentStyles[pricingMode]],
            )}
        >
            {showDiscount && discount && discount !== '0' && (
                <Price
                    productPrice={productShopify?.price || price}
                    className={classNames(
                        'price price--original',
                        [
                            styles['price--original'],
                            parentStyles['price--original'],
                        ],
                        [styles.price, parentStyles.price],
                    )}
                    styles={styles}
                    prefix={prefix}
                />
            )}
            <Price
                productPrice={productShopify?.price || price}
                discount={discount}
                currency={showCurrency}
                className={classNames(
                    'price price--current',
                    [styles['price--current'], parentStyles['price--current']],
                    [styles.price, parentStyles.price],
                )}
                styles={styles}
                prefix={prefix}
                suffix={suffix}
            />
            {showTax && !showPriceObservations && <span className={classNames(
                [styles.taxes, parentStyles.taxes],
                'taxes',
            )}>
                (plus tax, if applicable)
            </span>}
            {isSubscription && showPriceObservations && <div
                className={classNames(
                    styles.observationMessage,
                )}
            >
                <p>${formatPrice(productShopify?.price || price, discount)} today, ${formatPrice(productShopify?.price || price, discount)} when your first order is prepared and shipped.{' '}
                    {showTax && <span>
                        Plus tax, if applicable.
                    </span>}
                </p>
            </div>}
        </div>
    );
}
