import Link from 'next/link';
import styles from './PageNavigation.module.scss';

export default function Nav({ data }) {
    const { topLinks } = data;

    return (
        <div className={styles.nav}>
            {topLinks ? (
                <ul className={styles.links}>
                    {topLinks.map((link) => {
                        const { url, title } = link.fields;
                        return (
                            <li key={url}>
                                <Link href={url}>
                                    {title}
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            ) : null}
        </div>
    );
}
