import { useContext } from 'react';
import { StyleContext } from 'utils/context/StyleContextProvider';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import Image from 'components/Layout/Module/Image';
import { formatExternalVideoURL } from '@viome/vnxt-nucleus';
import SectionModules from 'containers/Sections/SectionModules';
import InlineText from './inlineText';

const InlineBlock = (node, styles) => {
    const blockMetaData = node.data.target.sys,
        blockType = blockMetaData.contentType?.sys?.id || blockMetaData.type,
        blockData = node.data.target.fields,
        parentStyles = useContext(StyleContext),
        CTA = dynamic(import('components/Tailwind/Components/CTA'), {
            ssr: false,
        }),
        Countdown = dynamic(import('components/Shell/CountdownTimer/CountdownTimer'), {
            ssr: false,
        });

    let blockContent = <div />;

    if (blockType === 'componentCta') {
        blockContent = <CTA data={blockData} />;
    } else if (blockType === 'inlineText') {
        blockContent = InlineText(node, styles, false);
    } else if (blockType === 'componentCountdownTimer') {
        blockContent = (
            <Countdown
                data={blockData}
                clockID={`${blockMetaData.id}${Math.floor(
                    Math.random() * 100,
                )}`}
            />
        );
    } else if (blockType === 'inlineMedia') {
        // Legacy Block components
        const { align, caption, title, videoUrl, media, width } = blockData,
            inlineStyles = {
                width: `${width || 100}%`,
            };

        if (videoUrl) {
            blockContent = (
                <div
                    className={classNames(
                        parentStyles?.iframe,
                        'relative h-0 w-full',
                    )}
                >
                    <iframe
                        frameBorder="0"
                        allow="encrypted-media"
                        allowFullScreen
                        src={formatExternalVideoURL(videoUrl)}
                        title={title}
                        className="absolute inset-0 h-full w-full"
                    />
                </div>
            );
        } else if (media) {
            const imageDetails = media.fields.file.details.image;
            blockContent = (
                <Image
                    data={media}
                    options={{
                        layout: 'responsive',
                        maxWidth: imageDetails?.width,
                    }}
                />
            );
        }

        blockContent = (
            <div
                className={classNames(
                    parentStyles?.inlineBlock,
                    align && parentStyles[`inlineBlock--${align}`],
                    'my-10',
                )}
                style={inlineStyles}
            >
                {blockContent}
                {caption && (
                    <div
                        className={classNames(parentStyles?.caption, 'mt-1.5')}
                    >
                        <p className="text-left italic">{caption}</p>
                    </div>
                )}
            </div>
        );
    } else if (blockType === 'Asset') {
        blockContent = (
            <Image data={blockData} options={{ layout: 'responsive' }} />
        );
    } else if (blockType === 'moduleImage') {
        blockContent = (
            <Image
                data={blockData}
                options={{
                    layout: 'responsive',
                    maxWidth: blockData.displayWidth,
                    align: blockData.align,
                    layoutOptions: blockData.layoutOptions,
                }}
            />
        );
    } else if (blockType.includes('20')) {
        blockContent = <SectionModules modules={[node.data.target]} />;
    }

    return blockContent;
};

export default InlineBlock;
