import { useState } from 'react';
import Link from 'next/link';
import classNames from 'classnames';
import { PointerArrowIcon as PointerArrow } from '@viome/vnxt-nucleus';
import styles from './PageNavigation.module.scss';

export default function PageNavigation({ data }) {
    const { topLinks } = data,
        [active, setActive] = useState(0),
        onClick = (index) => {
            setActive(index);
        };

    return (
        <div className={styles.nav}>
            {topLinks ? (
                <ul className={styles.links}>
                    {topLinks.map((link, index) => {
                        const { url, title } = link.fields;
                        return (
                            <li key={url}>
                                <Link
                                    href={url}
                                    className={classNames(
                                        active === index && styles.active,
                                    )}
                                    onClick={() => onClick(index)}
                                    onKeyPress={() => onClick(index)}
                                    role="button"
                                    tabIndex="0"
                                >
                                    {title}
                                    <PointerArrow />
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            ) : null}
        </div>
    );
}
