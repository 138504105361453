import classNames from 'classnames';
import { useViewport } from 'utils/context/ViewportContextProvider';
import { getViewportFromWidth } from '@viome/vnxt-nucleus';

const Heading = (
    styles,
    node,
    nodeChildren,
    mobileHeaderFontSize,
    bodyTextScaling,
) => {
    const { width } = useViewport(),
        viewPort = getViewportFromWidth(width),
        inlineStyles = {},
        HeadingElement = {
            'heading-1': 'h1',
            'heading-2': 'h2',
            'heading-3': 'h3',
            'heading-4': 'h4',
            'heading-5': 'h5',
            'heading-6': 'h6',
        }[node.nodeType];

    if (viewPort === 'Small' && mobileHeaderFontSize) {
        inlineStyles.fontSize = `${mobileHeaderFontSize}px`;
    }

    return (
        <HeadingElement
            className={classNames(
                styles.headings,
                bodyTextScaling && styles[bodyTextScaling],
            )}
            style={inlineStyles}
        >
            {nodeChildren}
        </HeadingElement>
    );
};

export default Heading;
