import StyleContextProvider from 'utils/context/StyleContextProvider';
import SectionBasic from './SectionBasic';
import styles from './HeroProduct.module.scss';

export default function HeroProduct({ data }) {
    return (
        <StyleContextProvider styles={styles}>
            <SectionBasic
                data={data}
                sectionWrapper={styles.hero}
                additionalClasses={data.class}
                moduleWrapper={
                    data.class === 'ambassador__hero' && styles.container
                }
            />
        </StyleContextProvider>
    );
}
