import classNames from 'classnames';
import StyleContextProvider from 'utils/context/StyleContextProvider';
import { matchCSSModule } from '@viome/vnxt-nucleus';
import SectionBasic from '../SectionBasic';
import styles from './DecodeActivateOptimize.module.scss';

export default function Decode({ data }) {
    const classes = data?.class;

    return (
        <StyleContextProvider styles={styles}>
            <SectionBasic
                data={data}
                sectionWrapper={classNames(
                    'viome__section__wrapper',
                    styles.dao,
                    matchCSSModule(classes, styles),
                )}
            />
        </StyleContextProvider>
    );
}
