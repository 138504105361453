import classNames from 'classnames';
import styles from './Accordion.module.scss';
import AccordionItem from './AccordionItem';
import Text from '../Text';

const styleMap = {
    'Smaller Text': styles.small,
    'Larger Text': styles.large,
    'Legacy Style': styles.legacy,
};

export default function Accordion({ data, darkMode }) {
    const {
            items,
            style,
            body,
            bodyAfter,
            fullWidth,
            borderColor,
            isRainbowBorder,
        } = data,
        bodyText = body?.fields,
        bodtAfterText = bodyAfter?.fields,
        accordionStyle = styleMap[style] || styles.small,
        itemStyles = {};

    if (borderColor) {
        itemStyles.borderColor = `${borderColor}`;
    }

    return (
        <div
            className={classNames(
                styles.Accordion,
                accordionStyle,
                fullWidth === true && styles.full,
                darkMode && `${styles.dark}`,
            )}
        >
            {bodyText && (
                <div className={styles.Accordion__body}>
                    <Text data={bodyText} />
                </div>
            )}
            {items.map((item, index) => (
                <AccordionItem
                    key={Math.random() * index}
                    data={item}
                    itemStyles={itemStyles}
                    isRainbowBorder={isRainbowBorder}
                />
            ))}
            {bodtAfterText && (
                <div
                    className={classNames(
                        styles.Accordion__body,
                        styles['Accordion__body--after'],
                    )}
                >
                    <Text data={bodtAfterText} />
                </div>
            )}
        </div>
    );
}
