import React, { useEffect } from 'react';
import Carousel from 'containers/Sections/Carousel';
import StyleContextProvider from 'utils/context/StyleContextProvider';
import styles from './ImmunityInYourBodyCarousel.module.scss';

export default function ImmunityInYourBodyCarousel({ data, options }) {
    options.autoPlay = false;
    options.showArrows = true;
    options.showIndicators = true;

    const getIndicatorTitle = (index) =>
        data.modules.items[index] ? data.modules?.items[index].title : '';
    useEffect(() => {
        const slidesNavAnchors = document.querySelectorAll(
            `.${styles.Carousel} .control-dots li`,
        );

        slidesNavAnchors.forEach((item, index) => {
            item.textContent = getIndicatorTitle(index);
        });
    }, []);
    return (
        <StyleContextProvider styles={styles}>
            <Carousel data={data} options={options} moduleStyles={styles} />
        </StyleContextProvider>
    );
}
