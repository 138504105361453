import { useContext } from 'react';
import StyleContextProvider, {
    StyleContext,
} from 'utils/context/StyleContextProvider';
import Basic from './Basic';
import styles from './ViomeInfo.module.scss';

export default function OurMission({ data }) {
    const stylesContext = useContext(StyleContext) || styles || {};

    return (
        <StyleContextProvider styles={stylesContext}>
            <Basic data={data} />
        </StyleContextProvider>
    );
}
