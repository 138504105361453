import Link from 'next/link';
import classNames from 'classnames';
import HorizontalScroll from './HorizontalScroll';
import styles from './HorizontalScrollTestimonial.module.scss';

export default function HorizontalScrollTestimonials({ data }) {
    const modules = data?.modules,
        modulesCount = modules && modules.length;

    return (
        <>
            <HorizontalScroll
                data={data}
                moduleStyles={styles}
                modulesCount={modulesCount}
            />
            <div className={classNames(styles.button)}>
                <Link href="/testimonials">
                    See Viome Testimonials
                </Link>
            </div>
        </>
    );
}
