import Hero from 'containers/Sections/Hero';
import HeroProduct from 'containers/Sections/HeroProduct';
import HeroGIPlan from 'containers/Sections/HeroGIPlan';
import TopicHeroSection from 'containers/Topics/TopicHeroSection';
import LandingHero from 'containers/Sections/Hero/LandingHero';
import OverlayHero from 'containers/Sections/Hero/OverlayHero';

const heroMap = {
    'viome-modern__product-hero': HeroProduct,
    'hero--gi-plan': HeroGIPlan,
    'topic-hero-section': TopicHeroSection,
    'landing-hero': LandingHero,
    'overlay-hero': OverlayHero,
    ambassador__hero: HeroProduct,
};

export default function determineHero(sectionClass) {
    return heroMap[sectionClass] || Hero;
}
