import Link from 'next/link';
import classNames from 'classnames';
import Image from 'components/Layout/Module/Image';
import { PointerArrowIcon as PointerArrow } from '@viome/vnxt-nucleus';
import styles from './Guide.module.scss';

export default function Guide({ fields, darkMode }) {
    const { title, featuredImage, metaDescription, canonicalUrl } = fields,
        description = `${metaDescription.substring(0, 100)}...`,
        guideType = `Viome Guide`.toUpperCase(),
        linkText = `Read`;

    return (
        <div className={classNames(styles.Guide, darkMode && `${styles.dark}`)}>
            <div className={styles.featuredImage}>
                <Image data={featuredImage} options={{ layout: 'intrinsic' }} />
            </div>
            <div className={styles.detail}>
                <h3 className={styles.guideType}>{guideType}</h3>
                <h4 className={styles.title}>{title}</h4>
                <p className={styles.description}>{description}</p>
                <div className={styles.fill} />
                <Link href={`${canonicalUrl}`}>
                    <p
                        className={classNames(
                            styles.link,
                            'flex',
                            'items-center',
                        )}
                    >
                        {linkText} <PointerArrow />
                    </p>
                </Link>
            </div>
        </div>
    );
}
