import { createContext } from 'react';

export const RowContext = createContext();

/**
 * Context Provider for passing data to child components
 *
 * @param  {map}        styles      Data
 * @param  {function}   children    Function to render children
 * @return {function}
 */
const RowContextProvider = ({ data, children }) => (
    <RowContext.Provider value={data}>{children}</RowContext.Provider>
);

export default RowContextProvider;
