import classNames from 'classnames';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Hyperlink from 'components/Tailwind/Text/Hyperlink';
import Paragraph from 'components/Text/Paragraph';
import Heading from 'components/Text/Heading';
import InlineBlock from 'components/Text/InlineBlock';
import InlineEntry from 'components/Text/InlineEntry';
import TextRenderer from 'components/Text/Text';
import styles from './Text.module.scss';

const styleMap = {
    Centered: classNames(styles.centered, 'centered'),
    'Centered / Left @ Tablet': classNames(
        styles['left--tablet'],
        'left--tablet',
        styles.full,
    ),
    'Centered / Left @ Desktop': classNames(
        styles['left--desktop'],
        'left--desktop',
        styles.full,
    ),
    'Left Aligned': styles.left,
    'Left Aligned - Full Width': classNames(styles.left, styles.full),
    'Paris - List': styles.paris,
    HR: styles.hr,
};

export default function Text({ data }) {
    const {
            body,
            style,
            bodyTextScaling,
            verticalAlign,
            mobileHeaderFontSize,
        } = data,
        textStyle = styleMap[style] || styles.centered,
        options = {
            renderNode: {
                [BLOCKS.PARAGRAPH]: (node, nodeChildren) =>
                    Paragraph(
                        nodeChildren,
                        styles,
                        bodyTextScaling,
                        verticalAlign,
                    ),
                [BLOCKS.HEADING_1]: (node, nodeChildren) =>
                    Heading(
                        styles,
                        node,
                        nodeChildren,
                        mobileHeaderFontSize,
                        bodyTextScaling,
                    ),
                [BLOCKS.HEADING_2]: (node, nodeChildren) =>
                    Heading(
                        styles,
                        node,
                        nodeChildren,
                        mobileHeaderFontSize,
                        bodyTextScaling,
                    ),
                [BLOCKS.HEADING_3]: (node, nodeChildren) =>
                    Heading(styles, node, nodeChildren, null, bodyTextScaling),
                [BLOCKS.HEADING_4]: (node, nodeChildren) =>
                    Heading(styles, node, nodeChildren, null, bodyTextScaling),
                [BLOCKS.HEADING_5]: (node, nodeChildren) =>
                    Heading(styles, node, nodeChildren, null, bodyTextScaling),
                [BLOCKS.HEADING_6]: (node, nodeChildren) =>
                    Heading(styles, node, nodeChildren, null, bodyTextScaling),
                [BLOCKS.EMBEDDED_ASSET]: (node) => InlineBlock(node, styles),
                [BLOCKS.EMBEDDED_ENTRY]: (node) => InlineBlock(node, styles),
                [INLINES.EMBEDDED_ENTRY]: (node) => InlineEntry(node, styles),
                [INLINES.HYPERLINK]: (node) => Hyperlink(node),
            },
            renderText: (txt) => TextRenderer(txt),
        };

    return (
        <div className={classNames(styles.text, textStyle, 'text')}>
            {body && documentToReactComponents(body, options)}
        </div>
    );
}
