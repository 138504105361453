import { applyHashMarkToHexCode } from '@viome/vnxt-nucleus';

const bullets = (color) => ({
        check: (
            <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M1 4.455 5.107 9 10 1"
                    stroke={applyHashMarkToHexCode(color)}
                    strokeWidth="2.596"
                    fill="none"
                    fillRule="evenodd"
                />
            </svg>
        ),
        plus: (
            <svg
                height="13"
                viewBox="0 0 13 13"
                width="13"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="m29.706 5994.876v-4.441h4.467v-3.066h-4.467v-4.467h-3.066v4.467h-4.467v3.066h4.467v4.441z"
                    fill={applyHashMarkToHexCode(color)}
                    transform="translate(-22 -5982)"
                />
            </svg>
        ),
        circleCheck: (
            <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g
                    stroke={applyHashMarkToHexCode(color)}
                    strokeWidth="3"
                    fill="none"
                    fillRule="evenodd"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M2 14c0 6.627 5.373 12 12 12s12-5.373 12-12S20.627 2 14 2 2 7.373 2 14h0z" />
                    <path d="m19.348 10.51-5.81 7.748a1.5 1.5 0 0 1-2.26.16l-3-3" />
                </g>
            </svg>
        ),
    }),
    Bullet = ({ type, color }) => (type ? bullets(color)[type] || null : null);

export default Bullet;
