import { createContext } from 'react';

export const PromoContext = createContext();

/**
 * Context Provider for passing data to child components
 *
 * @param  {map}        styles      Data
 * @param  {function}   children    Function to render children
 * @return {function}
 */
const PromoContextProvider = ({ data, children }) => (
    <PromoContext.Provider value={data}>{children}</PromoContext.Provider>
);

export default PromoContextProvider;
