import { useContext } from 'react';
import { InfoIcon as Info} from '@viome/vnxt-nucleus'
import Popup from 'components/Tailwind/Components/PopupGeneric';
import Text from 'components/Tailwind/Module/Text';
import { StyleContext } from 'utils/context/StyleContextProvider';

export default function CompareRecommendations({ product }) {
    const styles = useContext(StyleContext),
        { healthScoresBody } = product;

    return (
        <div className={styles.recommendationsWrapper}>
            <div className={styles.recommendations}>
                <div className={styles.recommendationsWrapper}>
                    <p className={styles.score}>
                        {product.productDetails?.subscore}+
                    </p>
                    <Popup
                        active={false}
                        title="Integrative Health Scores"
                        className="heading-5 heading"
                        icon={<Info />}
                    >
                        <Text
                            data={{
                                body: healthScoresBody,
                                style: 'Left Aligned',
                            }}
                        />
                    </Popup>
                </div>
            </div>
        </div>
    );
}
