import classNames from 'classnames';
import ProductGrid from 'components/Tailwind/Module/ProductGrid';
import Accordion from './Module/Accordion';
import Card from './Module/Card';
import Carousel from './Module/Carousel';
import HTML from './Module/HTML';
import Image from './Module/Image';
import NewsArticle from './Module/NewsArticle';
import PageNavigation from './Module/PageNavigation';
import Publication from './Module/Publication';
import Study from './Module/Study';
import Text from './Module/Text';
import Video from './Module/Video';
import Person from '../Person';
import Gallery from './Module/Gallery';
import Row from './Row';
import PageSet from './Module/PageSet';

import Grants from '../Misc/Grants';
import Careers from '../Misc/Careers';

export default function Modules({ modules, styles, columns, wrap, darkMode }) {
    const modulesArray = [],
        length = (modules && modules.length) || 0,
        max = (wrap === true ? length : columns) || length;

    if (length > 0) {
        for (let i = 0; i <= max - 1 && i < length; i++) {
            const moduleFields = modules[i],
                id = moduleFields?.sys.id,
                moduleType = moduleFields.__typename;

            let moduleContent;

            if (moduleType === 'moduleImage') {
                moduleContent = (
                    <Image
                        data={moduleFields.src}
                        mp4={moduleFields.mp4}
                        options={{
                            align: moduleFields.align,
                            caption: moduleFields.caption,
                            width: moduleFields.displayWidth,
                            layout: moduleFields.layout,
                            priority: moduleFields.priority,
                            quality: moduleFields.quality,
                        }}
                    />
                );
            } else if (moduleType === 'moduleText') {
                moduleContent = <Text data={moduleFields} />;
            } else if (moduleType === 'video') {
                moduleContent = <Video data={moduleFields} />;
            } else if (moduleType === 'moduleAccordion') {
                moduleContent = (
                    <Accordion data={moduleFields} darkMode={darkMode} />
                );
            } else if (moduleType === 'moduleCarousel') {
                moduleContent = <Carousel data={moduleFields} />;
            } else if (moduleType === 'moduleCard') {
                moduleContent = <Card data={moduleFields} />;
            } else if (moduleType === 'newsItem') {
                moduleContent = <NewsArticle data={moduleFields} />;
            } else if (moduleType === 'productsNavMenu') {
                moduleContent = <PageNavigation data={moduleFields} />;
            } else if (moduleType === 'publication') {
                moduleContent = <Publication data={moduleFields} />;
            } else if (moduleType === 'study') {
                moduleContent = <Study data={moduleFields} />;
            } else if (moduleType === 'author') {
                moduleContent = (
                    <Person data={moduleFields} columns={columns || 1} />
                );
            } else if (moduleType === 'moduleGallery') {
                moduleContent = <Gallery data={moduleFields} />;
            } else if (moduleType === 'moduleHtml') {
                moduleContent = <HTML data={moduleFields} />;
            } else if (moduleType === 'moduleMisc') {
                const miscModuleType = moduleFields.moduleType;
                if (miscModuleType === 'VLS - Grants Form') {
                    moduleContent = <Grants />;
                } else if (miscModuleType === 'Careers List') {
                    moduleContent = <Careers />;
                } else if (miscModuleType.includes('Product Grid')) {
                    const miscModules = moduleFields.modules || [];
                    moduleContent = (
                        <ProductGrid
                            modules={miscModules}
                            type={miscModuleType.split(' - ')[1]}
                        />
                    );
                }
            } else if (moduleType === 'modulePageSet') {
                moduleContent = <PageSet data={moduleFields} />;
            } else {
                moduleContent = <Row data={moduleFields} nested />;
            }
            modulesArray.push(
                <div
                    className={classNames(
                        styles.modules,
                        moduleType && styles[moduleType],
                        moduleType && moduleType,
                    )}
                    key={`${i}${id}`}
                >
                    {moduleContent}
                </div>,
            );
        }

        return modulesArray;
    }
    return null;
}
