import Link from 'next/link';
import classNames from 'classnames';
import format from 'date-fns/format';
import styles from './Publication.module.scss';

export default function NewsArticle({ data }) {
    const { name, url, publisher, publishedDate } = data;

    return (
        <div className={classNames(styles.publication)}>
            <img
                src="https://images.ctfassets.net/qk4l4jfatr3e/48eKAqofwxFTfwHPRxcPBX/7fb84b78e25d4dfc0d91c9338c1355f9/group-3.svg"
                alt="book icon"
            />
            <Link href={url} target="_blank">
                <h4>{name}</h4>
            </Link>
            <p>{publisher}</p>
            <p>
                <time dateTime={publishedDate}>
                    {format(new Date(publishedDate), 'LLLL d, yyyy')}
                </time>
            </p>
        </div>
    );
}
