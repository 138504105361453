import classNames from 'classnames';
import StyleContextProvider from 'utils/context/StyleContextProvider';
import SectionBasic from '../Sections/SectionBasic';
import styles from './TopicArticle.module.scss';
import TopicArticleSubNavigation from './TopicArticleSubNavigation';

export default function TopicArticle({ data }) {
    return (
        <StyleContextProvider styles={styles}>
            <div className={classNames(styles['topic-article-layout'])}>
                <aside>
                    <TopicArticleSubNavigation />
                </aside>
                <article>
                    <SectionBasic data={data} />
                </article>
            </div>
        </StyleContextProvider>
    );
}
