import classNames from 'classnames';
import { useViewport } from 'utils/context/ViewportContextProvider';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { renderSection, applyHashMarkToHexCode } from '@viome/vnxt-nucleus';
import Hyperlink from 'components/Tailwind/Text/Hyperlink';
import InlineBlock from './InlineBlock';
import InlineEntry from './InlineEntry';
import RenderBullet from './RenderBullet';
import TextRenderer from './Text';

const listStyles = {
        'Border-Bottom': 'borderBottom',
        'Split List': 'splitList',
        'No Bullets': 'noBullets',
        'No Margin': 'noMargins',
    },
    InlineText = (node, styles, isInline) => {
        const blockType = node.data.target.sys.contentType.sys.id,
            blockData = node.data.target.fields,
            {
                body,
                gradient,
                color,
                fontFamily,
                border,
                backgroundColor,
                listStyle,
                bulletStyle,
                bulletColor,
                hideAt,
                layout,
            } = blockData,
            headingClass = (node, children) => (
                <span
                    className={classNames(
                        styles[node.nodeType],
                        node.nodeType,
                        styles.heading,
                        'heading',
                    )}
                >
                    {children}
                </span>
            ),
            ContainerElement = isInline ? 'span' : 'p',
            inlineStyles = {},
            listAttrs = {},
            listClasses = [],
            htmlId = layout?.htmlId,
            options = {
                renderNode: {
                    [BLOCKS.PARAGRAPH]: (node, children) => children,
                    [BLOCKS.UL_LIST]: (node, children) => (
                        <ul {...listAttrs}>{children}</ul>
                    ),
                    [BLOCKS.OL_LIST]: (node, children) => (
                        <ol {...listAttrs}>{children}</ol>
                    ),
                    [BLOCKS.LIST_ITEM]: (node, children) => (
                        <li
                            style={{
                                borderColor: `${applyHashMarkToHexCode(
                                    bulletColor,
                                )}`,
                            }}
                            className={styles[bulletStyle]}
                        >
                            <RenderBullet
                                type={bulletStyle}
                                color={bulletColor}
                            />
                            {children}
                        </li>
                    ),
                    [BLOCKS.HEADING_1]: (node, children) =>
                        headingClass(node, children),
                    [BLOCKS.HEADING_2]: (node, children) =>
                        headingClass(node, children),
                    [BLOCKS.HEADING_3]: (node, children) =>
                        headingClass(node, children),
                    [BLOCKS.HEADING_4]: (node, children) =>
                        headingClass(node, children),
                    [BLOCKS.HEADING_5]: (node, children) =>
                        headingClass(node, children),
                    [BLOCKS.HEADING_6]: (node, children) =>
                        headingClass(node, children),
                    [BLOCKS.HR]: () => (
                        <hr
                            style={{
                                borderColor: `${applyHashMarkToHexCode(
                                    bulletColor,
                                )}`,
                            }}
                        />
                    ),
                    [INLINES.HYPERLINK]: (node) => Hyperlink(node),
                    [INLINES.EMBEDDED_ENTRY]: (node) =>
                        InlineEntry(node, styles),
                    [BLOCKS.EMBEDDED_ASSET]: (node) =>
                        InlineBlock(node, styles),
                },
                renderText: (txt) => TextRenderer(txt, styles),
            },
            fontMap = {
                Calibre: classNames(styles.calibre),
                'Calibre-Medium': classNames(styles['calibre--medium']),
                'Calibre-Semibold': classNames(styles['calibre--semibold']),
                Tiempos: classNames(styles.tiempos),
            },
            checkHideAt = (viewport) => {
                if (hideAt) {
                    return !hideAt.includes(viewport);
                }
                return true;
            },
            { width } = useViewport(),
            showBody = renderSection(
                {
                    renderAtMobile: checkHideAt('Mobile'),
                    renderAtTablet: checkHideAt('Tablet'),
                    renderAtDesktop: checkHideAt('Desktop'),
                },
                width,
            );

        if (listStyle) {
            listStyle.forEach((style) => {
                listClasses.push(styles[listStyles[style]]);
            });
            if (bulletStyle) {
                listClasses.push(styles.customBullets);
            }
            listAttrs.className = classNames(listClasses);
        }

        let blockContent = <ContainerElement />;

        if (color) {
            inlineStyles.color = `${applyHashMarkToHexCode(color)}`;
        }

        if (backgroundColor) {
            inlineStyles.background = `${applyHashMarkToHexCode(
                backgroundColor,
            )}`;
        }

        if (blockType === 'inlineText') {
            blockContent = (
                <ContainerElement
                    className={classNames(
                        gradient &&
                            `${styles.gradient} ${
                                styles[`gradient--${gradient}`]
                            }`,
                        fontFamily && fontMap[fontFamily],
                        border && styles.border,
                        backgroundColor && styles.backgroundColor,
                        styles.inline,
                    )}
                    style={inlineStyles}
                    id={htmlId}
                >
                    {body && documentToReactComponents(body, options)}
                </ContainerElement>
            );
        }
        return showBody && blockContent;
    };

export default InlineText;
