import Text from 'components/Tailwind/Module/Text';

export default function ProductDescription({ bundleDescription, styles }) {
    return (
        <div className={styles.bundleDescription}>
            <Text
                data={{
                    body: bundleDescription,
                    style: 'Left Aligned',
                }}
            />
        </div>
    );
}
