import Link from 'next/link';
import Image from '../Image';
import styles from './NewsArticle.module.scss';

export default function NewsArticle({ data }) {
    return (
        <Link
            href={data.link}
            className={styles.article}
            target="_blank"
            rel="noopener noreferrer"
        >
            <div className={styles.container}>
                <Image data={data.img} options={{ layout: 'intrinsic' }} />
                <p className={styles.author}>
                    {data.author.replace('By ', '')}
                </p>
                <p>{data.title}</p>
            </div>
        </Link>
    );
}
