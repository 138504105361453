import classNames from 'classnames';
import StyleContextProvider from 'utils/context/StyleContextProvider';
import SectionBasic from './SectionBasic';
import styles from './Grid.module.scss';

export default function Grid({ data }) {
    return (
        <StyleContextProvider styles={styles}>
            <SectionBasic
                data={data}
                moduleWrapper={classNames(styles.gridContainer)}
            />
        </StyleContextProvider>
    );
}
