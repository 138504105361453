import { useContext } from 'react';
import classNames from 'classnames';
import StyleContextProvider, {
    StyleContext,
} from 'utils/context/StyleContextProvider';
import SectionBasic from '../SectionBasic';
import SectionBody from '../SectionBody';
import styles from './Basic.module.scss';

export default function BasicComponent({ data }) {
    const { style, theme, fontFamily, bodyPadding } = data,
        stylesContext = useContext(StyleContext) || styles || {};

    return (
        <StyleContextProvider styles={stylesContext}>
            <SectionBasic
                data={data}
                sectionWrapper={stylesContext[style]}
                additionalClasses={classNames(
                    'basic',
                    theme,
                    fontFamily,
                    bodyPadding && 'bodyPadding',
                )}
            >
                <SectionBody data={data} />
            </SectionBasic>
        </StyleContextProvider>
    );
}
