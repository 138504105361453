import React, { useRef, useContext } from 'react';
import classNames from 'classnames';
import { DataContext } from 'utils/context/DataContextProvider';
import useDetectOutsideClick from 'lib/scripts/useDetectOutsideClick';
import styles from './TopicArticleSubNavigation.module.scss';

export default function TopicArticleSubNavigation() {
    const matchSubNavClass = 'topic-section',
        matchSubnavSkipClass = 'topic-subnav-skip-link',
        dataContext = useContext(DataContext),
        dropdownRef = useRef(null),
        [isActive, setIsActive] = useDetectOutsideClick(
            dropdownRef,
            false,
            true,
            true,
        ),
        onClick = (e) => {
            setIsActive(!isActive);
            e.preventDefault();
        },
        getSections = (data) => {
            const section = Array.isArray(data)
                ? data
                      .filter((section) => {
                          const sectionField = section?.fields;
                          return (
                              sectionField?.class?.indexOf(matchSubNavClass) >
                                  -1 &&
                              !(
                                  sectionField.class.indexOf(
                                      matchSubnavSkipClass,
                                  ) > -1
                              ) &&
                              sectionField.id &&
                              sectionField.id !== '' &&
                              sectionField.text?.content[0]?.content[0]
                                  ?.value !== ''
                          );
                      })
                      .map((section) => {
                          const sectionFields = section?.fields;
                          return {
                              id: sectionFields?.id || '',
                              name:
                                  sectionFields.text?.content[0]?.content[0]
                                      ?.value || '',
                          };
                      })
                : null;
            return section;
        },
        navArticles = getSections(dataContext);

    return (
        <div className={classNames(styles['topic-article-sub-navigation'])}>
            <nav>
                <div
                    className={styles.button}
                    onClick={onClick}
                    onKeyPress={onClick}
                    role="button"
                    tabIndex={0}
                >
                    <a
                        className={classNames(
                            styles['topic-article-header-link'],
                        )}
                    >
                        <h3>Overview</h3>
                    </a>
                </div>

                <ul
                    ref={dropdownRef}
                    className={classNames(isActive && styles.active)}
                >
                    {navArticles?.map((article) => (
                        <li key={article.name}>
                            <a href={`#${article.id}`}>{article.name}</a>
                        </li>
                    ))}
                </ul>
            </nav>
        </div>
    );
}
