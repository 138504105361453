import classNames from 'classnames';
import dynamic from 'next/dynamic';
import RenderBullet from 'components/Tailwind/Text/RenderBullet';
import styles from 'pages/compare/Compare.module.scss';
import StyleContextProvider from 'utils/context/StyleContextProvider';
import ReactHtmlParser from 'react-html-parser';
import CompareHead from './CompareHead';
import CompareRecommendations from './CompareRecommendations';
import CompareDetails from './CompareDetails';
import CompareColumn from './CompareColumn';

export default function CompareSection({
    products,
    selectedItems,
    slug,
    sectionOrder = [
        'head',
        'optimizes',
        'healthScores',
        'food',
        'productDetails.supplements',
        'productDetails.prepro',
        'productDetails.lozenges',
        'sampleType',
        'productDetails.details',
        'cta',
    ],
    popup = false,
    attachDiscount = false,
    children,
    isAffiliate = false,
}) {
    const parsedProducts = {},
        compareSections = {
            head: { section: [] },
            healthScores: { title: 'What We Analyze', section: [] },
            optimizes: {
                title: popup
                    ? 'Integrated Health Scores'
                    : 'Food Recommendations & Supplements Informed by Your Health Scores',
                test: 'These scores represent an overall assessment of key health areas that impact how you feel, look, and age.',
                section: [],
                listStyle: 'scoreBullets',
            },
            food: {
                title: 'Food List & Recommendations',
                section: [],
            },
            'productDetails.supplements': {
                title: 'Precision Supplements',
                section: [],
            },
            'productDetails.prepro': {
                title: 'Precision Probiotics + Prebiotics',
                section: [],
            },
            'productDetails.lozenges': {
                title: 'MyBiotics Oral Lozenges',
                section: [],
            },
            sampleType: { title: 'Sample Type', section: [] },
            'productDetails.details': {
                title: 'Details',
                section: [],
            },
            cta: { section: [] },
            title: { section: [] },
            children: { section: [] },
            test: { title: 'Test', section: [] },
        },
        CTA = dynamic(import('components/Tailwind/Components/CTA'), {
            ssr: false,
        }),
        currentSectionOrder = sectionOrder;

    products.forEach((module) => {
        const productFields = module?.fields || module,
            { sku } = productFields;

        parsedProducts[sku] = {
            ...productFields,
        };
    });

    const selectedProducts = selectedItems || Object.keys(parsedProducts);

    selectedProducts.forEach((item, index) => {
        const currentProduct = parsedProducts[item],
            {
                name,
                productDetails = {},
                shopifySubscriptionPlanProd,
                shopifySubscriptionPlanStaging,
                discountCode,
                salesMessage,
            } = currentProduct,
            isPlan =
                shopifySubscriptionPlanProd || shopifySubscriptionPlanStaging;

        if (currentProduct) {
            const CTASection = () => (
                <CTA
                    data={{
                        style: isPlan ? 'Secondary' : 'Standard',
                        mode: isPlan ? 'Dark' : 'Light',
                        text: isPlan ? 'Order and Subscribe' : 'Order Test',
                        product: currentProduct,
                        pricingMode: 'Light',
                        pricing: [
                            'Show Price',
                            isPlan ? '' : 'Show Slashed Price',
                            'Show Currency',
                        ],
                        // Only include discount code if ( attachDiscount| salesMessage | discountCode) are all true or exist
                        parameters:
                            attachDiscount &&
                            salesMessage &&
                            discountCode &&
                            `?discount=${discountCode}`,
                    }}
                />
            );
            currentSectionOrder.forEach((order) => {
                let sectionBlock = null;

                if (order === 'head') {
                    sectionBlock = (
                        <CompareHead
                            CTASection={CTASection}
                            product={currentProduct}
                            isPlan={isPlan}
                            key={name}
                        />
                    );
                } else if (order === 'cta') {
                    sectionBlock = (
                        <div className={styles.ctaSection}>
                            <CTASection />
                        </div>
                    );
                } else if (order === 'test') {
                    sectionBlock = <p>{productDetails.test}</p>;
                } else if (order === 'children') {
                    sectionBlock = children;
                } else if (order === 'title') {
                    sectionBlock = (
                        <p
                            className={classNames(
                                styles.productName,
                                styles.bold,
                                'heading heading-3',
                            )}
                        >
                            {name}
                        </p>
                    );
                } else if (order === 'food') {
                    sectionBlock = (
                        <ul className={styles.check}>
                            <li>
                                <RenderBullet type="colored" color="#CA3541" />
                                Avoid Foods
                            </li>
                            <li>
                                <RenderBullet type="colored" color="#009E82" />
                                Superfoods
                            </li>
                            <li>
                                <RenderBullet type="colored" color="#294BC3" />
                                Enjoy Foods
                            </li>
                            <li>
                                <RenderBullet type="colored" color="#A24AB0" />
                                Minimize Foods
                            </li>
                        </ul>
                    );
                } else if (
                    order === 'productDetails.supplements' ||
                    order === 'productDetails.prepro' ||
                    order === 'productDetails.lozenges'
                ) {
                    const currentBlock = productDetails[order.split('.').pop()];
                    if (currentBlock) {
                        sectionBlock = (
                            <CompareDetails
                                currentBlock={currentBlock}
                                index={index}
                            />
                        );
                    }
                } else {
                    let currentDetails;
                    if (order.includes('.')) {
                        currentDetails = productDetails[order.split('.').pop()];
                    } else {
                        currentDetails = currentProduct[order];
                    }
                    if (currentDetails) {
                        if (typeof currentDetails === 'object') {
                            sectionBlock = (
                                <div>
                                    {productDetails.health_scores && (
                                        <>
                                            <p>
                                                {productDetails.health_scores}
                                            </p>
                                            <br />
                                            <br />
                                        </>
                                    )}
                                    <ul
                                        className={classNames(
                                            compareSections[order].listStyle &&
                                                styles[
                                                    compareSections[order]
                                                        .listStyle
                                                ],
                                        )}
                                    >
                                        {currentDetails.map((detail) => (
                                            <li key={`${detail}-${index}`}>
                                                <RenderBullet
                                                    type={
                                                        compareSections[order]
                                                            .listStyle
                                                    }
                                                    label={detail}
                                                />
                                                {detail}
                                            </li>
                                        ))}
                                    </ul>
                                    {order === 'optimizes' && !popup && (
                                        <CompareRecommendations
                                            product={currentProduct}
                                        />
                                    )}
                                </div>
                            );
                        } else {
                            sectionBlock = (
                                <p>{ReactHtmlParser(currentDetails)}</p>
                            );
                        }
                    }
                }

                compareSections[order] = compareSections[order] ?? {};
                compareSections[order].section =
                    compareSections[order]?.section ?? [];
                compareSections[order].section.push(sectionBlock || <p>__</p>);
            });
        }
    });

    return (
        <StyleContextProvider styles={styles}>
            <div
                className={classNames(
                    styles.compareContainer,
                    isAffiliate && styles.affiliate,
                )}
            >
                <section
                    className={classNames(
                        styles.productGrid,
                        styles.compareWrapper,
                        popup && styles.popup,
                    )}
                >
                    {currentSectionOrder.map((order, index) => (
                        <CompareColumn
                            order={order}
                            index={index}
                            column={compareSections[order]}
                            slug={slug}
                            key={`${order}_${index}`}
                        />
                    ))}
                </section>
            </div>
        </StyleContextProvider>
    );
}
