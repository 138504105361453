import StyleContextProvider from 'utils/context/StyleContextProvider';
import SectionBasic from './SectionBasic';
import styles from './AboutProduct.module.scss';

export default function AboutProduct({ data }) {
    return (
        <StyleContextProvider styles={styles}>
            <SectionBasic
                data={data}
                moduleWrapper={styles['about-sections']}
            />
        </StyleContextProvider>
    );
}
