import { createContext } from 'react';

export const StyleContext = createContext();

/**
 * Context Provider for the CSS Modules
 *
 * @param  {map}        styles      Page level styles
 * @param  {function}   children    Function to render children
 * @return {function}
 */
const StyleContextProvider = ({ styles, children }) => (
    <StyleContext.Provider value={styles}>{children}</StyleContext.Provider>
);
export default StyleContextProvider;
