import { useContext } from 'react';
import Link from 'next/link';
import classNames from 'classnames';
import Image from 'components/Layout/Module/Image';
import { StyleContext } from 'utils/context/StyleContextProvider';

export default function CompareHead({ CTASection, product, isPlan = false }) {
    const styles = useContext(StyleContext),
        { name, slug, bundleUrl, subtitle, compareImage } = product,
        imageOptions = {
            priority: true,
            quality: 100,
        },
        imageFields = compareImage?.fields,
        productURL = bundleUrl || `/products/${slug}`;

    return (
        <div className={classNames(styles.head)}>
            <div className={classNames(styles.headWrapper)}>
                <Link href={productURL} className={styles.top}>
                    <Image data={imageFields} options={imageOptions} />
                    <p
                        className={classNames(
                            styles.name,
                            styles.bold,
                            'heading heading-5',
                        )}
                    >
                        {name}
                    </p>
                    {subtitle && (
                        <p
                            className={classNames(
                                styles.subtitle,
                                'heading',
                                !isPlan && styles.test,
                            )}
                        >
                            {subtitle}
                        </p>
                    )}
                </Link>
                <div className={styles.bottom}>
                    <CTASection />
                </div>
            </div>
        </div>
    );
}
