import Carousel from 'containers/Sections/Carousel';
import StyleContextProvider from 'utils/context/StyleContextProvider';
import styles from './BiologicalAgeCarousel.module.scss';

export default function BiologicalAgeCarousel({ data, options }) {
    options.autoPlay = false;
    options.showArrows = true;
    options.showIndicators = false;

    return (
        <StyleContextProvider styles={styles}>
            <Carousel data={data} options={options} moduleStyles={styles} />
        </StyleContextProvider>
    );
}
