import { useContext } from 'react';
import classNames from 'classnames';
import { StyleContext } from 'utils/context/StyleContextProvider';

export default function CompareColumn({ order, index, column, slug }) {
    const styles = useContext(StyleContext);

    return (
        <div className={styles.section} key={`${order}-${index}`}>
            {column?.title && (
                <p
                    className={classNames(
                        'heading',
                        styles.subtitle,
                        styles.bold,
                    )}
                >
                    {column?.title}
                </p>
            )}
            <div
                className={classNames(
                    styles.grid,
                    slug === 'plans' && styles.plans,
                )}
            >
                {column?.section}
            </div>
        </div>
    );
}
