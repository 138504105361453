import dynamic from 'next/dynamic';
import InlineText from './inlineText';

const InlineEntry = (node, styles) => {
    const blockMetaData = node.data.target.sys,
        blockType = blockMetaData.contentType?.sys?.id,
        blockData = node.data.target.fields,
        Countdown = dynamic(import('components/Shell/CountdownTimer/CountdownTimer'), {
            ssr: false,
        });

    let blockContent = <span />;

    if (blockType === 'moduleImage') {
        const { src, displayWidth, name } = blockData,
            file = src?.fields.file;

        blockContent = (
            <img src={file.url} width={`${displayWidth}px`} alt={name} />
        );
    } else if (blockType === 'inlineText') {
        blockContent = InlineText(node, styles, true);
    } else if (blockType === 'componentCountdownTimer') {
        blockContent = (
            <Countdown
                data={blockData}
                clockID={`${blockMetaData.id}${Math.floor(
                    Math.random() * 100,
                )}`}
                inline
            />
        );
    }
    return blockContent;
};

export default InlineEntry;
