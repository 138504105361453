import classNames from 'classnames';
import StyleContextProvider from 'utils/context/StyleContextProvider';
import SectionBasic from './SectionBasic';
import SectionBody from './SectionBody';
import styles from './HorizontalScroll.module.scss';

export default function HorizontalScroll({ data, moduleStyles, modulesCount }) {
    const sectionStyles = moduleStyles || styles;

    return (
        <StyleContextProvider styles={sectionStyles}>
            <SectionBasic
                data={data}
                sectionWrapper={classNames(
                    sectionStyles.HorizontalScroll__container,
                )}
            >
                <div className={classNames(sectionStyles.HorizontalScroll)}>
                    <SectionBody
                        data={data}
                        moduleWrapper={classNames(
                            sectionStyles.HorizontalScroll__items,
                            modulesCount < 5 && sectionStyles.center,
                        )}
                    />
                </div>
            </SectionBasic>
        </StyleContextProvider>
    );
}
