import classNames from 'classnames';

const styleMap = {
        Top: 'items-start',
        Middle: 'items-center',
        Bottom: 'items-end',
        Centered: 'justify-center',
        'Centered / Left @ Tablet': 'justify-start md:justify-center',
        'Centered / Left @ Desktop': 'justify-start lg:justify-center',
        'Left Aligned': 'justify-start',
        'Left / Centered @ Tablet': 'justify-center md:justify-start',
        'Left / Centered @ Desktop': 'justify-center lg:justify-start',
        'Right Aligned': 'justify-end',
    },
    Paragraph = (nodeChildren, styles, bodyTextScaling, verticalAlign, style) =>
        nodeChildren[0][0] === '' && nodeChildren.length === 1 ? (
            <br />
        ) : (
            <p
                className={classNames(
                    bodyTextScaling && styles[bodyTextScaling],
                    verticalAlign &&
                        `flex ${styleMap[verticalAlign]} gap-5 ${styleMap[style]}`,
                )}
            >
                {nodeChildren}
            </p>
        );

export default Paragraph;
