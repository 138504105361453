import classNames from 'classnames';
import { useViewport } from 'utils/context/ViewportContextProvider';
import { getViewportFromWidth } from '@viome/vnxt-nucleus';
import ConditionalWrapper from 'utils/ConditionalWrapper';

const Heading = (
    styles,
    node,
    nodeChildren,
    mobileHeaderFontSize,
    bodyTextScaling,
    useP = false,
) => {
    const { width } = useViewport(),
        viewPort = getViewportFromWidth(width),
        inlineStyles = {},
        HeadingElement = useP
            ? 'p'
            : {
                  'heading-1': 'h1',
                  'heading-2': 'h2',
                  'heading-3': 'h3',
                  'heading-4': 'h4',
                  'heading-5': 'h5',
                  'heading-6': 'h6',
              }[node.nodeType];

    if (viewPort === 'Small' && mobileHeaderFontSize) {
        inlineStyles.fontSize = `${mobileHeaderFontSize}px`;
    }

    return (
        <HeadingElement
            className={classNames(
                styles.headings,
                useP && ['heading', node.nodeType],
            )}
            style={inlineStyles}
        >
            <ConditionalWrapper
                condition={bodyTextScaling && bodyTextScaling !== 'Normal'}
                wrapper={(nodeChildData) => (
                    <span className={classNames(styles[bodyTextScaling])}>
                        {nodeChildData}
                    </span>
                )}
            >
                {nodeChildren}
            </ConditionalWrapper>
        </HeadingElement>
    );
};

export default Heading;
