import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { CarrotArrowIcon as CarrotArrow, PointerArrowIcon as PointerArrow, parseModules, getViewportFromWidth } from '@viome/vnxt-nucleus';
import Modules from 'components/Tailwind/Modules';
import { useViewport } from 'utils/context/ViewportContextProvider';
import ConditionalWrapper from 'utils/ConditionalWrapper';
import layoutClasses from 'components/Tailwind/Utils/LayoutClasses';
import Text from '../Text';
import styles from './Selector.module.scss';

export default function Selector({ data, darkMode }) {
    const { header, selectors, selected, interactionType, settings } = data,
        [active, setActive] = useState(null),
        selectorsRef = useRef(null),
        layoutOptions = layoutClasses(settings, null, styles, null),
        onClick = (index) => {
            setActive(index);
        },
        onClose = () => {
            setActive(null);
        },
        interactionAttrs = (index) => {
            let currentIndex = index;
            const lastIndex = selectors.length - 1;

            // We can pass '-/+' as well to adjust based on the current index
            if (index === '-') {
                currentIndex = active > 0 ? active - 1 : 0;
            } else if (index === '+') {
                currentIndex = active < lastIndex ? active + 1 : lastIndex;
            }

            if (interactionType === 'hover') {
                return {
                    onMouseEnter: () => onClick(currentIndex),
                    onMouseLeave: () => onClick(0),
                };
            }

            if (interactionType.includes('click')) {
                return {
                    onClick: () => onClick(currentIndex),
                    onKeyPress: () => onClick(currentIndex),
                };
            }
            return {};
        },
        { width } = useViewport(),
        viewport = getViewportFromWidth(width),
        Selectors = ({ selectors }) =>
            selectors.map((module, index) => {
                const moduleType = module.sys.contentType.sys.id,
                    { fields } = module;

                let moduleContent;

                if (moduleType === 'moduleText') {
                    moduleContent = (
                        <div
                            className={classNames(
                                styles.selector,
                                active === index && styles.active,
                                index === 0 && !active && styles.default,
                            )}
                            {...interactionAttrs(index)}
                            role="button"
                            tabIndex="0"
                            aria-controls={module.name}
                            aria-expanded={active > -1}
                        >
                            <Text data={fields} />
                            <div
                                className={classNames(
                                    styles.icon,
                                    styles.arrow,
                                )}
                            >
                                {!settings?.selector?.hideArrow && (
                                    <PointerArrow />
                                )}
                            </div>
                        </div>
                    );
                } else if (
                    moduleType === 'sectionSingle20' ||
                    moduleType === 'sectionMultiColumn20'
                ) {
                    moduleContent = (
                        <Modules modules={parseModules([module])} />
                    );
                }

                return (
                    <div key={`${fields.name}${index}selector`}>
                        {moduleContent}
                    </div>
                );
            });

    // Autoscroll to active element
    useEffect(() => {
        if (
            interactionType.includes('click') &&
            (viewport !== 'Small' || interactionType === 'click-updated')
        ) {
            selectorsRef.current.scrollTo({
                left:
                    selectorsRef.current.children[0].offsetWidth * active +
                    10 * active,
                behavior: 'smooth',
            });
        }
    }, [active]);
    const darkModeGray = settings?.selector?.darkModeGray || false;

    return (
        <div
            className={classNames(
                styles[interactionType],
                darkMode && `${styles.dark}`,
                darkModeGray && `${styles.darkGrayBackgrouund}`,
                layoutOptions.classes,
            )}
            style={layoutOptions.styles}
        >
            <div
                className={classNames(
                    styles.selectors__wrapper,
                    selectors.length < 5 && styles.hidden,
                )}
            >
                <ConditionalWrapper
                    condition={
                        interactionType.includes('click') &&
                        (viewport !== 'Small' ||
                            interactionType === 'click-updated')
                    }
                    wrapper={(nodeChildData) => (
                        <>
                            <div
                                className={classNames(
                                    styles.selectors__btns,
                                    styles.prev,
                                    (!active || active === 0) && styles.disable,
                                )}
                                {...interactionAttrs('-')}
                                role="button"
                                tabIndex="0"
                                aria-label="Previous"
                            >
                                <CarrotArrow />
                            </div>
                            <div
                                className={classNames(
                                    styles.selectors,
                                    settings?.selector?.expanded &&
                                        styles.selectors__expanded,
                                )}
                                ref={selectorsRef}
                            >
                                {nodeChildData}
                            </div>
                            <div
                                className={classNames(
                                    styles.selectors__btns,
                                    styles.next,
                                    active === selectors.length - 1 &&
                                        styles.disable,
                                )}
                                {...interactionAttrs('+')}
                                role="button"
                                tabIndex="0"
                                aria-label="Next"
                            >
                                <CarrotArrow />
                            </div>
                        </>
                    )}
                    elseWrapper={(nodeChildData) => (
                        <div className={classNames(styles.selectors)}>
                            {nodeChildData}
                        </div>
                    )}
                >
                    <>
                        {header && (
                            <div className={classNames(styles.header)}>
                                <Text data={header.fields} />
                            </div>
                        )}
                        <Selectors selectors={selectors} />
                    </>
                </ConditionalWrapper>
            </div>

            <div
                className={classNames(
                    styles.selected,
                    styles[interactionType],
                    active === null && styles.default,
                )}
            >
                <Modules modules={parseModules([selected[active || 0]])} />
                <div
                    className={classNames(styles.icon, styles.close)}
                    onClick={onClose}
                    onKeyPress={onClose}
                    role="button"
                    tabIndex="0"
                    aria-label="close"
                    aria-expanded={active > -1}
                />
            </div>
        </div>
    );
}
