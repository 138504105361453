const ConditionalWrapper = ({ condition, wrapper, elseWrapper, children }) => {
    if (condition) {
        return wrapper(children);
    }
    if (elseWrapper) {
        return elseWrapper(children);
    }
    return children;
};

export default ConditionalWrapper;
