import classNames from 'classnames';

const Paragraph = (nodeChildren, styles, bodyTextScaling, verticalAlign) =>
    nodeChildren[0][0] === '' && nodeChildren.length === 1 ? (
        <br />
    ) : (
        <p
            className={classNames(
                bodyTextScaling && styles[bodyTextScaling],
                verticalAlign && `${styles.flex} ${styles[verticalAlign]}`,
            )}
        >
            {nodeChildren}
        </p>
    );

export default Paragraph;
