import classNames from 'classnames';
import Link from 'next/link';
import styles from './PageSet.module.scss';

export default function PageSet({ data }) {
    const { name, pages } = data,
        getFields = (page) => {
            const { fields } = page,
                { title, url } = page && fields;

            if (title && fields?.seo?.fields?.canonicalUrl) {
                return { title, url: fields?.seo?.fields?.canonicalUrl };
            }

            if (fields?.metaTitle && fields?.canonicalUrl) {
                return { title: fields?.metaTitle, url: fields?.canonicalUrl };
            }

            return { title, url };
        };

    return (
        <div className={classNames(styles.pageSet)}>
            <h3 className={classNames(styles.pageSet__Heading)}>{name}</h3>
            <ul className={classNames(styles.pageSet__List)}>
                {pages &&
                    pages.length > 0 &&
                    pages.map((page) => {
                        const { title, url } = getFields(page);
                        if (!title || !url) {
                            return null;
                        }
                        return (
                            <li key={title}>
                                <Link href={url}>
                                    {title}
                                </Link>
                            </li>
                        );
                    })}
            </ul>
        </div>
    );
}
