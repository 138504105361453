import React, { useRef } from 'react';
import classNames from 'classnames';
import { Picture } from 'react-responsive-picture';
import SectionBody from 'containers/Sections/SectionBody';
import { cBoolean } from '@viome/vnxt-nucleus';
import useDetectOutsideClick from '../../lib/scripts/useDetectOutsideClick';
import styles from './Card.module.scss';

export default function Card({ data }) {
    const cardRef = useRef(null),
        color = data?.color,
        triggerText = data?.triggerText,
        settings = data?.settings,
        isFlip = cBoolean(data.isFlip),
        hasUx = cBoolean(data.hasUx),
        frontImg = settings?.cardFrontImgSrc,
        bodyAfterBody = data?.bodyAfter,
        bodyBefore = {
            text: data?.body,
            settings,
        },
        bodyAfter = {
            text: bodyAfterBody,
            settings,
        },
        [isActive, setIsActive] = useDetectOutsideClick(
            cardRef,
            cBoolean(settings?.active),
            true,
            typeof settings?.outside === 'undefined' || settings?.outside,
        ),
        onClick = () => {
            setIsActive(!isActive);
        };

    let clickAttrs = {};
    if (bodyAfterBody) {
        clickAttrs = {
            onClick,
            onKeyPress: onClick,
            role: 'button',
            tabIndex: 0,
            ref: cardRef,
        };
    }

    return (
        <div className={classNames(styles.Card, 'Card', isFlip && styles.Flip)}>
            <div
                className={classNames(
                    styles.Card__wrapper,
                    'Card__wrapper',
                    isActive && `active ${styles.active}`,
                    color && `Card--${color}`,
                )}
                style={color && { color }}
                {...clickAttrs}
            >
                <SectionBody
                    data={bodyBefore}
                    bodyClass={classNames(
                        styles.before,
                        'Card__before',
                        frontImg && `${styles['has-image']} 'has-image`,
                        !bodyAfterBody && `noAfter ${styles.noAfter}`,
                    )}
                    prebody={
                        hasUx ? (
                            <svg
                                height="18"
                                viewBox="0 0 20 18"
                                width="20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="m44.446 1597.634 4.193 2.862 11.765-11.858c1.15-1.431 2.231-.205 1.15 1.227l-11.901 15.538c-.541.75-1.555.817-2.164.068l-5.139-5.997c-1.15-1.432.788-2.937 2.096-1.84z"
                                    fill={`${color || '#000'}`}
                                    transform="translate(-42 -1588)"
                                />
                            </svg>
                        ) : null
                    }
                >
                    {frontImg && (
                        <Picture src={frontImg} alt="Card before icon" />
                    )}
                    {triggerText && (
                        <p
                            className={classNames(
                                styles.trigger,
                                'Card__trigger',
                            )}
                        >
                            {triggerText}
                        </p>
                    )}
                    {hasUx && bodyAfterBody && (
                        <svg
                            height="9"
                            viewBox="0 0 14 9"
                            width="14"
                            xmlns="http://www.w3.org/2000/svg"
                            className={styles.dropdown}
                        >
                            <path
                                d="m330.243 1593.243-.025 7.975-7.975.025"
                                fill="none"
                                stroke="#151833"
                                strokeWidth="2"
                                transform="matrix(.70710678 .70710678 -.70710678 .70710678 905.975719 -1358.866994)"
                            />
                        </svg>
                    )}
                </SectionBody>
                <SectionBody
                    data={bodyAfter}
                    bodyClass={classNames(styles.after, 'Card__after')}
                />
            </div>
        </div>
    );
}
