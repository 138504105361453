import StyleContextProvider from 'utils/context/StyleContextProvider';
import SectionBasic from './SectionBasic';
import styles from './HeroGIPlan.module.scss';

export default function HeroGIPlan({ data }) {
    return (
        <StyleContextProvider styles={styles}>
            <SectionBasic data={data} sectionWrapper={styles.hero} />
        </StyleContextProvider>
    );
}
