import React from 'react';
import Carousel from 'containers/Sections/Carousel';
import StyleContextProvider from 'utils/context/StyleContextProvider';
import styles from './MediaScienceQuotesCarousel.module.scss';

export default function MediaScienceQuotesCarousel({ data, options }) {
    options.autoPlay = false;
    options.showArrows = false;
    options.showIndicators = true;

    return (
        <StyleContextProvider styles={styles}>
            <Carousel data={data} options={options} moduleStyles={styles} />
        </StyleContextProvider>
    );
}
