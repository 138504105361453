import React from 'react';
import Link from 'next/link';

const superscriptReferences = (txt) => {
    const formatCitation = (text) => {
            const returnText = (
                <sup key={text}>
                    <Link href="#references">{text}</Link>
                </sup>
            );

            if (returnText.length === 0) {
                return '';
            }
            return returnText;
        },
        parsedText =
            Array.isArray(txt) &&
            txt.map((t, i) => {
                t =
                    typeof t[i] === 'string' &&
                    t[i].match(/^[0-9]+$/) !== null ? (
                        formatCitation(t[i])
                    ) : (
                        <sup>{t[i]}</sup>
                    );
                return t;
            });
    return parsedText;
};

export default superscriptReferences;
