import classNames from 'classnames';
import { Carousel } from 'react-responsive-carousel';
import Image from 'components/Common/Image';
import SectionBasic from './SectionBasic';
import Section from './Section';
import styles from './Carousel.module.scss';

export default function CarouselComponent({
    data,
    moduleStyles,
    options,
    children,
}) {
    const modules = data?.modules,
        media = data?.media,
        settings = data?.settings,
        style = data?.style,
        sectionStyles = moduleStyles || styles,
        carouselProps = Object.assign(
            {
                autoPlay: true,
                emulateTouch: true,
                infiniteLoop: true,
                showArrows: false,
                showIndicators: true,
                showStatus: false,
                showThumbs: false,
                stopOnHover: true,
                swipeable: true,
                useKeyboardArrows: false,
            },
            options || {},
        ),
        carouselItems = [];

    if (media) {
        media.forEach((mediaItem) => {
            carouselItems.push(
                <Image
                    image={{
                        images: [mediaItem],
                        options: settings,
                    }}
                    key={Math.random()}
                />,
            );
        });
    }

    if (modules) {
        if (!modules.isArray && modules.items?.length > 1) {
            modules.items?.forEach((module) => {
                carouselItems.push(
                    <Section
                        data={module}
                        sectionStyles={sectionStyles}
                        key={module?.title}
                    />,
                );
            });
        } else {
            modules.forEach((module) => {
                const moduleData = module?.fields;
                carouselItems.push(
                    <Section
                        data={moduleData}
                        sectionStyles={sectionStyles}
                        key={moduleData?.title}
                    />,
                );
            });
        }
    }

    return (
        <SectionBasic
            data={data}
            styles={sectionStyles}
            sectionWrapper={classNames(
                'viome__section__wrapper',
                'Carousel',
                style ? sectionStyles.carousel : sectionStyles.Carousel,
                style && sectionStyles[style],
            )}
        >
            <div
                className={classNames(
                    'Carousel__wrapper',
                    style
                        ? sectionStyles.carousel__wrapper
                        : sectionStyles.Carousel__wrapper,
                )}
            >
                <Carousel {...carouselProps}>{carouselItems}</Carousel>
                {children && <>{children}</>}
            </div>
        </SectionBasic>
    );
}
