import React, { useRef } from 'react';
import classNames from 'classnames';
import StyleContextProvider from 'utils/context/StyleContextProvider';
import useDetectOutsideClick from 'lib/scripts/useDetectOutsideClick';
import SectionBasic from '../Sections/SectionBasic';
import styles from './TopicSection.module.scss';

export default function TopicSection({ data }) {
    const collapsibleClass = 'topic-section-collapsible',
        expandingRef = useRef(null),
        dropdownRef = useRef(null),
        [isActive, setIsActive] = useDetectOutsideClick(
            dropdownRef,
            false,
            true,
            true,
        ),
        onClick = () => {
            setIsActive(!isActive);
        },
        divClass = classNames(data.class);

    let isCollapsibleAttr = {};
    if (divClass.includes(collapsibleClass)) {
        isCollapsibleAttr = {
            onClick,
            onKeyPress: onClick,
            role: 'button',
            tabIndex: 0,
            ref: expandingRef,
        };
    }
    return (
        <StyleContextProvider styles={styles}>
            <div
                className={classNames(isActive && `active ${styles.active}`)}
                {...isCollapsibleAttr}
            >
                <SectionBasic data={data} />
            </div>
        </StyleContextProvider>
    );
}
