import React from 'react';
import regexifyString from 'regexify-string';
import RenderBullet from 'components/Tailwind/Text/RenderBullet';
import Link from 'next/link';

const Text = (txt, bulletStyle) => {
    const formatCitation = (text) => {
            const returnText = regexifyString({
                pattern: /\[\d+\]/gim,
                decorator: (match) => {
                    const cleanMatch = match.replace(/[[\]]/g, '');
                    return (
                        <sup key={cleanMatch}>
                            <Link href="#references">
                                {cleanMatch}
                            </Link>
                        </sup>
                    );
                },
                input: text,
            });
            if (returnText.length === 0) {
                return '';
            }
            return returnText;
        },
        coloredBullets = (text, bulletStyle) => {
            const returnText = regexifyString({
                pattern: /\[.*?\]/gim,
                decorator: (match) => {
                    const cleanMatch = match.replace(/[[\]]/g, '');
                    return (
                        <RenderBullet type={bulletStyle} color={cleanMatch} />
                    );
                },
                input: Array.isArray(text) ? text[0] : text,
            });
            if (returnText.length === 0) {
                return '';
            }
            return returnText;
        },
        splitText = txt.split('\n'),
        parsedText = splitText.map((t, i) => {
            t = formatCitation(t);
            if (bulletStyle === 'colored') {
                t = coloredBullets(t, bulletStyle);
            }

            return i > 0 ? (
                <React.Fragment key={Math.random()}>
                    <br />
                    {t}
                </React.Fragment>
            ) : (
                t
            );
        });

    return parsedText;
};

export default Text;
