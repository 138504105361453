import AboutProduct from 'containers/Sections/AboutProduct';
import Basic from 'containers/Sections/Basic';
import Benefits from 'containers/Sections/Benefits';
import ProductCompare from 'containers/Sections/ProductCompare';
import ProductCTA from 'containers/Sections/ProductCTA';
import CLIAPrivacy from 'containers/Sections/CLIAPrivacy';
import Terms from 'containers/Sections/Terms';
import FAQs from 'containers/Sections/FAQs';
import HowItWorks from 'containers/Sections/HowItWorks';
import OurMission from 'containers/Sections/OurMission';
import Perks from 'containers/Sections/Perks';
import CountDecode from 'containers/Sections/Count-Decode';
import CountActivate from 'containers/Sections/Count-Activate';
import CountOptimize from 'containers/Sections/Count-Optimize';
import Product from 'containers/Sections/Product';
import RecodedMisc from 'containers/Sections/RecodedMisc';
import SEOLinks from 'containers/Sections/SEOLinks';
import ThreeSteps from 'containers/Sections/ThreeSteps';
import ViomeCoreTech from 'containers/Sections/ViomeCoreTech';

import Generic from 'containers/Sections/Generic/Generic';
import Promotional from 'containers/Sections/Promotional/Promotional';
import MadeForYou from 'containers/Sections/MadeForYou/MadeForYou';
import DAO from 'containers/Sections/DecodeActivateOptimize/DecodeActivateOptimize';

import TopicArticle from 'containers/Topics/TopicArticle';
import TopicArticleSubNavigation from 'containers/Topics/TopicArticleSubNavigation';
import TopicMoreTopicsNavigation from 'containers/Topics/TopicMoreTopicsNavigation';
import TopicSection from 'containers/Topics/TopicSection';

import YouAreLimitless from 'components/BiologicalAge/YouAreLimitless';
import BiologicalAgeCards from 'components/BiologicalAge/BiologicalAgeCards';
import PersonalizedNutritionContent from 'components/BiologicalAge/PersonalizedNutritionContent';
import Podcast from 'components/Common/Podcast';
import SimpleText from 'components/Common/SimpleText';
import PrecisionNutrientsCards from 'components/immunity/PrecisionNutrientsCards';
import ImmunityHero from 'components/immunity/ImmunityHero';
import VersusOthers from 'components/OurScience/VersusOthers';
import hero from './hero';

export default function BasicComponent(sectionClass) {
    let basicComponent;

    if (!sectionClass) {
        return null;
    }
    if (sectionClass.indexOf('generic') === 0) {
        basicComponent = Generic;
    } else if (sectionClass.indexOf('immunity-hero') > -1) {
        basicComponent = ImmunityHero;
    } else if (sectionClass.indexOf('hero') > -1) {
        basicComponent = hero(sectionClass);
    } else if (
        sectionClass.indexOf('about-product') > -1 ||
        sectionClass.indexOf('aboutProduct') > -1
    ) {
        basicComponent = AboutProduct;
    } else if (
        sectionClass.indexOf('basic ') > -1 ||
        sectionClass === 'basic'
    ) {
        basicComponent = Basic;
    } else if (sectionClass.indexOf('viome-modern__product-benefits') > -1) {
        basicComponent = Benefits;
    } else if (
        sectionClass.indexOf('viome-modern__product-module--compare') > -1 ||
        sectionClass === 'compare'
    ) {
        basicComponent = ProductCompare;
    } else if (sectionClass.indexOf('productCTA') > -1) {
        basicComponent = ProductCTA;
    } else if (sectionClass.indexOf('clia-privacy') > -1) {
        basicComponent = CLIAPrivacy;
    } else if (sectionClass.indexOf('viome-terms') > -1) {
        basicComponent = Terms;
    } else if (sectionClass.indexOf('faqs') > -1) {
        basicComponent = FAQs;
    } else if (sectionClass.indexOf('how-it-works') > -1) {
        basicComponent = HowItWorks;
    } else if (sectionClass.indexOf('our-mission') > -1) {
        basicComponent = OurMission;
    } else if (sectionClass.indexOf('viome-modern__product-perks') > -1) {
        basicComponent = Perks;
    } else if (sectionClass.indexOf('product-module') > -1) {
        if (sectionClass.indexOf('product-module--decode') > -1) {
            basicComponent = CountDecode;
        } else if (sectionClass.indexOf('product-module--activate') > -1) {
            basicComponent = CountActivate;
        } else if (sectionClass.indexOf('product-module--optimize') > -1) {
            basicComponent = CountOptimize;
        } else {
            basicComponent = Product;
        }
    } else if (sectionClass.indexOf('viome-modern__home__misc-recoded') > -1) {
        basicComponent = RecodedMisc;
    } else if (sectionClass.indexOf('seo-links') > -1) {
        basicComponent = SEOLinks;
    } else if (sectionClass.indexOf('threeSteps') > -1) {
        basicComponent = ThreeSteps;
    } else if (sectionClass.indexOf('simple-text') > -1) {
        basicComponent = SimpleText;
    } else if (sectionClass.indexOf('viome-core-technology') > -1) {
        basicComponent = ViomeCoreTech;
    } else if (sectionClass.indexOf('promotional') > -1) {
        basicComponent = Promotional;
    } else if (sectionClass.indexOf('decode-activate-optimize') > -1) {
        basicComponent = DAO;
    } else if (sectionClass.indexOf('made-for-you') > -1) {
        basicComponent = MadeForYou;
    } else if (sectionClass.indexOf('topic-article') > -1) {
        basicComponent = TopicArticle;
    } else if (sectionClass.indexOf('topic-article-sub-navigation') > -1) {
        basicComponent = TopicArticleSubNavigation;
    } else if (sectionClass.indexOf('topic-more-topics-navigation') > -1) {
        basicComponent = TopicMoreTopicsNavigation;
    } else if (sectionClass.indexOf('topic-section') > -1) {
        basicComponent = TopicSection;
    } else if (sectionClass.indexOf('you-are-limitless') > -1) {
        basicComponent = YouAreLimitless;
    } else if (sectionClass.indexOf('biological-age-cards') > -1) {
        basicComponent = BiologicalAgeCards;
    } else if (sectionClass.indexOf('personalized-nutrition-content') > -1) {
        basicComponent = PersonalizedNutritionContent;
    } else if (
        sectionClass.indexOf('immunity-precision-nutrients-cards') > -1
    ) {
        basicComponent = PrecisionNutrientsCards;
    } else if (sectionClass.indexOf('podcast-module') > -1) {
        basicComponent = Podcast;
    } else if (sectionClass.indexOf('our-science-versus-others') > -1) {
        basicComponent = VersusOthers;
    }
    return basicComponent;
}
