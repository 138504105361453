import classNames from 'classnames';
import StyleContextProvider from 'utils/context/StyleContextProvider';
import SectionBasic from './SectionBasic';
import styles from './RecodedMisc.module.scss';

export default function RecodedMisc({ data }) {
    return (
        <StyleContextProvider styles={styles}>
            <SectionBasic
                data={data}
                sectionWrapper={classNames(styles.RecodedMisc, styles.full)}
            />
        </StyleContextProvider>
    );
}
