const Code = (txt) => {
    const parsedText = txt.map((t, i) => {
        t =
            typeof t[i] === 'string' &&
            t[i].match(/^#[^ !@#$%^&*(),.?":{}|<>]*$/) !== null ? (
                <span id={t[i].replace('#', '')} />
            ) : (
                <code>{t[i]}</code>
            );
        return t;
    });
    return parsedText;
};

export default Code;
