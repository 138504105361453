import React from 'react';
import Row from 'components/Layout/Row';
import TailwindRow from 'components/Tailwind/Row';
import ConditionalWrapper from 'utils/ConditionalWrapper';
import { parseModules } from '@viome/vnxt-nucleus';
import Section from './Section';

export default function SectionModules({
    modules,
    styles,
    moduleWrapper,
    titleOveride,
}) {
    const parseModule = (moduleData) => {
        if (!moduleData.modules) {
            return parseModules([moduleData])[0];
        }
        return moduleData;
    };
    modules = modules?.items || modules;
    return modules && modules.length > 0 ? (
        <ConditionalWrapper
            condition={moduleWrapper}
            wrapper={(children) => (
                <div className={moduleWrapper}>{children}</div>
            )}
        >
            {modules.map((module) => {
                const moduleData = module?.fields || module,
                    moduleMeta = module?.sys,
                    moduleID = moduleMeta?.id,
                    contentType =
                        moduleMeta?.contentType?.sys?.id || module.__typename;

                return (
                    <React.Fragment key={moduleID + Math.random()}>
                        {titleOveride && (
                            <h4 id={moduleID}>
                                {`${moduleData?.name} (${moduleID})`}
                                <br />
                                <ul>
                                    {moduleData?.class && (
                                        <li>
                                            <u>{moduleData?.class}</u>
                                        </li>
                                    )}
                                    {moduleData?.theme && (
                                        <li>
                                            <u>{moduleData?.theme}</u>
                                        </li>
                                    )}
                                    {moduleData?.style && (
                                        <li>
                                            <u>{moduleData?.style}</u>
                                        </li>
                                    )}
                                </ul>
                            </h4>
                        )}
                        {contentType === 'sectionSingleColumn' ||
                        contentType === 'sectionMultiColumn' ||
                        contentType === 'sectionMultiColumn20' ||
                        contentType === 'sectionSingle20' ? (
                            contentType.includes('20') ? (
                                <TailwindRow data={parseModule(module)} />
                            ) : (
                                <Row data={parseModule(module)} />
                            )
                        ) : (
                            <Section
                                contentType={contentType}
                                data={moduleData}
                                sectionStyles={styles}
                            />
                        )}
                    </React.Fragment>
                );
            })}
        </ConditionalWrapper>
    ) : null;
}
