import React, { useEffect } from 'react';
import StyleContextProvider from 'utils/context/StyleContextProvider';
import SectionBasic from '../SectionBasic';
import styles from './MadeForYou.module.scss';

export default function Promotional({ data }) {
    useEffect(() => {
        const messages = document.querySelectorAll(`.${styles.messages__item}`);

        messages.forEach((message, idx) => {
            setTimeout(() => {
                message?.classList.add('active');
            }, 2500 * idx);
        });
    }, []);

    return (
        <StyleContextProvider styles={styles}>
            <SectionBasic data={data} />
        </StyleContextProvider>
    );
}
