import StyleContextProvider from 'utils/context/StyleContextProvider';
import SectionBasic from '../SectionBasic';
import styles from './Promotional.module.scss';

export default function Promotional({ data }) {
    return (
        <StyleContextProvider styles={styles}>
            <SectionBasic data={data} />
        </StyleContextProvider>
    );
}
