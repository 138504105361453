import classNames from 'classnames';
import { parseModules } from '@viome/vnxt-nucleus';
import Image from 'components/Layout/Module/Image';
import Video from 'components/Layout/Module/Video';
import Widths from 'components/Layout/Utils/Widths';
import TailwindRow from 'components/Tailwind/Row';
import Text from '../Text';
import styles from './HorizontalScroll.module.scss';

export default function HorizontalScroll({ data }) {
    const {
            name,
            modules,
            mobileItemWidth,
            tabletItemWidth,
            desktopItemWidth,
            __typename,
            sys,
        } = data,
        containerID = __typename + (sys?.id || name);

    return (
        <>
            <Widths
                widths={{
                    MOBILE: mobileItemWidth,
                    TABLET: tabletItemWidth,
                    DESKTOP: desktopItemWidth || tabletItemWidth,
                    XL: desktopItemWidth || tabletItemWidth,
                }}
                itemClass={containerID}
            />
            <div className={classNames(styles.horizontalScroll)}>
                {modules.map((module, index) => {
                    const moduleType = module.sys.contentType.sys.id,
                        { fields } = module;

                    let moduleContent;

                    if (moduleType === 'moduleImage') {
                        moduleContent = (
                            <Image
                                data={fields.src}
                                options={{
                                    maxWidth: fields.displayWidth,
                                    priority: index > 0,
                                    quality: fields.quality,
                                }}
                            />
                        );
                    } else if (moduleType === 'moduleText') {
                        moduleContent = <Text data={fields} />;
                    } else if (moduleType === 'video') {
                        moduleContent = <Video data={fields} />;
                    } else if (moduleType === 'sectionSingle20') {
                        moduleContent = (
                            <TailwindRow
                                data={parseModules([module])[0]}
                                nested
                            />
                        );
                    }

                    return (
                        <div className={containerID} key={`${name}${index}`}>
                            {moduleContent}
                        </div>
                    );
                })}
            </div>
        </>
    );
}
