import classNames from 'classnames';
import { CarrotArrowIcon as CarrotArrow } from '@viome/vnxt-nucleus';
import Text from '../Text';

export default function FaqsItem({ name, beforeBody, afterBody, styles }) {
    const cardStyles = styles?.styles || {},
        parentStyles = styles?.parentStyles || {};

    return (
        <div
            className={classNames(
                cardStyles.container,
                parentStyles.container,
                'py-4',
            )}
        >
            <div
                className={classNames(
                    cardStyles.question,
                    parentStyles.question,
                )}
            >
                <Text data={{ body: beforeBody, style: 'Left Aligned' }} />
                <CarrotArrow />
            </div>

            <div
                id={name}
                className={classNames(
                    cardStyles.answer,
                    parentStyles.answer,
                    'transition-all ease-in-out',
                )}
                tabIndex="{0}"
            >
                <Text data={{ body: afterBody, style: 'Left Aligned' }} />
            </div>
        </div>
    );
}
