import classNames from 'classnames';
import StyleContextProvider from 'utils/context/StyleContextProvider';
import SectionBasic from './SectionBasic';
import styles from './Count-Optimize.module.scss';

export default function Decode({ data }) {
    return (
        <StyleContextProvider styles={styles}>
            <SectionBasic
                data={data}
                sectionWrapper={classNames(
                    'viome__section__wrapper',
                    styles.Product,
                    styles['Product--optimize'],
                    data?.class.indexOf('--gi-plan') > -1 &&
                        styles['Product--gi-plan'],
                    data?.class.indexOf('--gut-intelligence') > -1 &&
                        styles['Product--gi'],
                )}
                moduleWrapper={styles.Product__wrapper}
            />
        </StyleContextProvider>
    );
}
