export default function ProductLabels({
    name,
    productDetails,
    styles,
    hasSale,
}) {
    const { labels = [] } = productDetails;

    return (
        <>
            {(labels.length > 0 || hasSale) && (
                <div className={styles.labels}>
                    {labels.map((label) => {
                        if (typeof label === 'object') {
                            return (
                                <p
                                    className={styles[label.style]}
                                    key={`${name}${label.text}`}
                                >
                                    <span>{label.text}</span>
                                </p>
                            );
                        }
                        return <p key={`${name}${label}`}>{label}</p>;
                    })}
                    {hasSale && (
                        <p className={styles.sale} key={`${name}sale`}>
                            Sale
                        </p>
                    )}
                </div>
            )}
        </>
    );
}
