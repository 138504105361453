import React, { useRef } from 'react';
import classNames from 'classnames';
import useDetectOutsideClick from 'lib/scripts/useDetectOutsideClick';
import styles from './Accordion.module.scss';
import Text from '../Text';

export default function AccordionItem({ data, itemStyles, isRainbowBorder }) {
    const cardRef = useRef(null),
        accordionData = data.fields,
        bodyBefore = accordionData?.beforeBody,
        bodyAfter = accordionData?.afterBody,
        [isActive, setIsActive] = useDetectOutsideClick(
            cardRef,
            false,
            true,
            false,
        ),
        onClick = () => setIsActive(!isActive),
        clickAttrs = {
            onClick,
            onKeyPress: onClick,
            role: 'button',
            tabIndex: 0,
            ref: cardRef,
        },
        style = 'Left Aligned - Full Width',
        itemClassList = [styles.Accordion__item, 'Accordion__item'];

    if (isRainbowBorder) {
        itemClassList.push(styles['Accordion__item--rainbow']);
    }

    return (
        <div className={classNames(itemClassList)} style={itemStyles || ''}>
            <div
                className={classNames(
                    styles.before,
                    isActive && `active ${styles.active}`,
                )}
                {...clickAttrs}
            >
                <Text
                    data={{
                        body: bodyBefore,
                        style,
                    }}
                />
                <svg
                    className={classNames(styles.arrow)}
                    height="9"
                    width="14"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="m12.9 1.243-5.658 5.621-5.656-5.621"
                        fill="none"
                        stroke="#151833"
                        strokeWidth="2"
                    />
                </svg>
            </div>
            <div className={styles.after}>
                <Text
                    data={{
                        body: bodyAfter,
                        style,
                    }}
                />
            </div>
        </div>
    );
}
