import React, { useRef } from 'react';
import classNames from 'classnames';
import SectionBody from 'containers/Sections/SectionBody';
import useDetectOutsideClick from '../../lib/scripts/useDetectOutsideClick';
import styles from './ExpandingText.module.scss';

export default function ExpandingText({ data }) {
    const expandingRef = useRef(null),
        [isActive, setIsActive] = useDetectOutsideClick(
            expandingRef,
            false,
            true,
        ),
        onClick = () => {
            setIsActive(!isActive);
        },
        body = {
            text: data?.body,
        },
        viewableLines = data?.viewableLines || 0,
        ctaText = data?.ctaText || 'More';

    return (
        <div className={classNames(styles.Expanding, 'Expanding')}>
            <div
                className={classNames(
                    styles.Expanding__wrapper,
                    'Expanding__wrapper',
                    isActive && `active ${styles.active}`,
                    styles[`viewable--${viewableLines}`],
                    `viewable--${viewableLines}`,
                )}
                onKeyPress={onClick}
                role="button"
                tabIndex={0}
                ref={expandingRef}
            >
                <SectionBody
                    data={body}
                    bodyClass={classNames(styles.body, 'Expanding__body')}
                />
            </div>
            {!isActive && (
                <button onClick={onClick} onKeyPress={onClick} type="button">
                    {ctaText}
                </button>
            )}
        </div>
    );
}
