import StyleContextProvider from 'utils/context/StyleContextProvider';
import SectionBasic from './SectionBasic';
import styles from './ViomeCoreTech.module.scss';

export default function ViomeCoreTech({ data }) {
    return (
        <StyleContextProvider styles={styles}>
            <SectionBasic
                data={data}
                sectionWrapper={styles.ViomeCoreTech}
                moduleWrapper={styles.techologies}
            />
        </StyleContextProvider>
    );
}
