import classNames from 'classnames';
import { matchCSSModule } from '@viome/vnxt-nucleus';
import Image from 'components/Common/Image';
import SectionBasic from '../SectionBasic';
import SectionBody from '../SectionBody';
import styles from './Marquee.module.scss';

export default function CarouselComponent({ data }) {
    const modules = data?.modules,
        media = data?.media,
        settings = data?.settings,
        style = data?.style,
        MediaSection = ({ media }) => {
            const marqueetems = [];

            if (media) {
                media.forEach((mediaItem, idx) => {
                    const itemClass = `marquee-item-${idx}`;
                    marqueetems.push(
                        <Image
                            image={{
                                images: [mediaItem],
                                options: settings,
                                classname: classNames(
                                    itemClass,
                                    styles[itemClass],
                                ),
                            }}
                            key={Math.random()}
                        />,
                    );
                });
            }

            /* Not Yet supported
            if (modules) {
                modules.forEach((module) => {
                    const moduleData = module?.fields;
                    marqueetems.push(
                        <Section
                            data={moduleData}
                            sectionStyles={styles}
                            key={moduleData?.title}
                        />,
                    );
                });
            }
            */

            return (
                <div
                    className={classNames(
                        'marquee__wrapper',
                        styles.marquee__wrapper,
                    )}
                >
                    <div
                        className={classNames(
                            'marquee__container',
                            styles.marquee__container,
                        )}
                    >
                        {marqueetems}
                        {marqueetems}
                    </div>
                </div>
            );
        };

    return (
        <SectionBasic
            data={data}
            styles={styles}
            sectionWrapper={classNames(
                'viome__section__wrapper',
                'marquee',
                styles.marquee,
                matchCSSModule(style, styles),
            )}
        >
            <SectionBody
                data={data}
                sectionStyles={styles}
                disableMedia
                moduleParser={() => null}
            />
            <MediaSection media={media} modules={modules} />
        </SectionBasic>
    );
}
