import { useEffect, useContext } from 'react';
import classNames from 'classnames';
import basic from 'utils/sectionRouter/basic';
import {
    CarouselComponent,
    CarouselSettings,
} from 'utils/sectionRouter/carousel';
import Card from 'components/Common/Card';
import GridComponent from 'utils/sectionRouter/grid';
import HorizontalScrollComponent from 'utils/sectionRouter/horizontalScroll';
import QuoteComponent from 'utils/sectionRouter/quote';
import DivWrapperComponent from 'utils/sectionRouter/divWrapper';
import { StyleContext } from 'utils/context/StyleContextProvider';
import ConditionalWrapper from 'utils/ConditionalWrapper';
import { useViewport } from 'utils/context/ViewportContextProvider';
import { matchCSSModule, renderSection } from '@viome/vnxt-nucleus';
import SectionBody from './SectionBody';
import SectionBasic from './SectionBasic';
import CTA from './CTA/CTA';
import BasicComponent from './Basic/Basic';
import HeroProduct from './Basic/HeroProduct';
import OurMission from './Basic/OurMission';
import RainbowList from './Basic/RainbowList';
import ViomeInfo from './Basic/ViomeInfo';
import Marquee from './Marquee/Marquee';
import Slideshow from './Slideshow/Slideshow';

export default function Section({ contentType, data }) {
    const sectionStyle = useContext(StyleContext) || {},
        title = data?.title,
        type = data?.type,
        moduleType = data?.moduleType,
        sectionClass = data?.class || data?.style || data?.classes,
        theme = data?.theme,
        settings = data?.settings || null,
        inlineCSS = settings?.css || {},
        parsedClasses = matchCSSModule(sectionClass, sectionStyle),
        { width } = useViewport(),
        showSection = renderSection(data, width);
    let ImportedComponent, options;

    if (contentType === 'cardModule') {
        return <Card key={title} data={data} />;
    }

    if (moduleType) {
        // Dynamically import sections
        switch (moduleType) {
            case 'carousel':
                ImportedComponent = CarouselComponent(sectionClass);
                options = CarouselSettings(data, settings, sectionClass);
                break;
            case 'horizontal-scroll':
                ImportedComponent = HorizontalScrollComponent(sectionClass);
                break;
            case 'grid':
                ImportedComponent = GridComponent(sectionClass);
                break;
            case 'quote':
                ImportedComponent = QuoteComponent(sectionClass);
                break;
            case 'basic':
                ImportedComponent = basic(sectionClass);
                break;
            default:
                break;
        }
    }

    if (contentType) {
        // Dynamically import sections
        switch (contentType) {
            case 'sectionBasic':
                if (theme === 'our-mission') {
                    ImportedComponent = OurMission;
                } else if (theme === 'hero-product') {
                    ImportedComponent = HeroProduct;
                } else if (theme === 'rainbow-list') {
                    ImportedComponent = RainbowList;
                } else if (theme === 'viome-info') {
                    ImportedComponent = ViomeInfo;
                } else {
                    ImportedComponent = BasicComponent;
                }
                break;
            case 'carousel':
                ImportedComponent = CarouselComponent(sectionClass);
                options = CarouselSettings(data, settings, null, width);
                break;
            case 'sectionCta':
                ImportedComponent = CTA;
                break;
            case 'marquee':
            case 'marquee-instagram':
                ImportedComponent = Marquee;
                break;
            case 'slideshow':
            case 'slideshow--hero':
                ImportedComponent = Slideshow;
                break;
            default:
                break;
        }
    }
    if (showSection) {
        if (
            type === 'multi-column' ||
            type === 'multi-column--full' ||
            ImportedComponent
        ) {
            return (
                <section
                    className={classNames(
                        'viome__section',
                        parsedClasses,
                        sectionClass,
                        sectionStyle && sectionStyle.section,
                        'legacy',
                    )}
                    key={data?.name}
                    style={inlineCSS}
                >
                    {ImportedComponent ? (
                        <ImportedComponent data={data} options={options} />
                    ) : (
                        <SectionBasic
                            data={data}
                            styles={sectionStyle}
                            sectionWrapper={classNames(
                                sectionStyle && sectionStyle.wrapper,
                            )}
                            isSubModule={sectionStyle}
                        />
                    )}
                </section>
            );
        }
        if (
            type === 'single-column' ||
            moduleType === 'divWrapper' ||
            contentType === 'textBlock'
        ) {
            return (
                <ConditionalWrapper
                    condition={moduleType === 'divWrapper'}
                    wrapper={() => (
                        <DivWrapperComponent
                            data={data}
                            sectionStyle={sectionStyle}
                        >
                            <SectionBody
                                data={data}
                                sectionStyles={sectionStyle}
                                style={inlineCSS}
                            />
                        </DivWrapperComponent>
                    )}
                >
                    <SectionBody
                        data={data}
                        sectionStyles={sectionStyle}
                        parsedClasses={parsedClasses} // switch it off?
                        bodyClass={sectionClass}
                        style={inlineCSS}
                    />
                </ConditionalWrapper>
            );
        }

        if (contentType === 'moduleFileDirectDownload') {
            useEffect(() => {
                const link = document.createElement('a');
                link.href =
                    data.fileUrl ||
                    `https://${data.attachment?.fields?.file?.url}`;
                link.download = data.slug || data.attachment?.fields?.file?.url;
                if (link.href) {
                    link.click();
                }
            }, []);

            return null;
        }

        return (
            <p key={data?.name}>
                ********
                {`${title}~~~${type}~~~${contentType}`}
                ******
            </p>
        );
    }
    return <section />;
}
