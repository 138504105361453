import React, { useContext } from 'react';
import { StyleContext } from 'utils/context/StyleContextProvider';
import classNames from 'classnames';
import { CURRENCY } from 'utils/constants';
import { formatPrice } from '@viome/vnxt-nucleus';

export default function Price({
    productPrice,
    discount = 0,
    currency,
    className,
    styles,
    prefix,
    suffix,
}) {
    const parentStyles = useContext(StyleContext) || {};

    return (
        <p className={classNames(className)}>
            {prefix && (
                <span
                    className={classNames(
                        [styles.prefix, parentStyles.prefix],
                        'prefix',
                    )}
                >
                    {prefix}
                </span>
            )}
            {formatPrice(productPrice, discount)}
            {suffix && (
                <span
                    className={classNames(
                        [styles.suffix, parentStyles.suffix],
                        'suffix',
                    )}
                >
                    {suffix}
                </span>
            )}
            {currency && (
                <span
                    className={classNames(
                        [styles.currency, parentStyles.currency],
                        'currency',
                    )}
                >
                    {CURRENCY}
                </span>
            )}
        </p>
    );
}
