import StyleContextProvider from 'utils/context/StyleContextProvider';
import { matchCSSModule } from '@viome/vnxt-nucleus';
import SectionBasic from '../SectionBasic';
import styles from './CTA.module.scss';

export default function CTA({ data }) {
    const style = data?.style;

    return (
        <StyleContextProvider styles={styles}>
            <SectionBasic
                data={data}
                sectionWrapper={matchCSSModule(style, styles)}
            />
        </StyleContextProvider>
    );
}
