import HorizontalScroll from 'containers/Sections/HorizontalScroll';
import HorizontalScrollTestimonials from 'containers/Sections/HorizontalScrollTestimonials';

export default function HorizontalScrollComponent(sectionClass) {
    let horizontalScrollComponent;

    if (sectionClass.indexOf('viome-modern__testimonials') > -1) {
        horizontalScrollComponent = HorizontalScrollTestimonials;
    }
    return horizontalScrollComponent || HorizontalScroll;
}
