import StyleContextProvider from 'utils/context/StyleContextProvider';
import SectionBasic from './SectionBasic';
import styles from './Terms.module.scss';

export default function Terms({ data }) {
    return (
        <StyleContextProvider styles={styles}>
            <SectionBasic data={data} sectionWrapper={styles['viome-terms']} />
        </StyleContextProvider>
    );
}
