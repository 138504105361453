import Link from 'next/link';
import StyleContextProvider from 'utils/context/StyleContextProvider';
import SectionBasic from './SectionBasic';
import styles from './ProductCompare.module.scss';

export default function ProductCompare({ data }) {
    return (
        <StyleContextProvider styles={styles}>
            <SectionBasic data={data} moduleWrapper={styles.items} />
            <div className={styles.button}>
                <Link href="/products">
                    Compare All Tests & Plans
                </Link>
            </div>
        </StyleContextProvider>
    );
}
