import React from 'react';
import StyleContextProvider from 'utils/context/StyleContextProvider';
import { useTable } from 'react-table';
import SectionBasic from 'containers/Sections/SectionBasic';
import styles from './VersusOthers.module.scss';

const VersusOthers = ({ data }) => {
    const inputTableData = data.settings?.TableData || [],
        inputColumnData = data.settings?.ColumnData || [],
        tableData = React.useMemo(() => inputTableData, []),
        columnData = React.useMemo(() => inputColumnData, []),
        tableInstance = useTable({ columns: columnData, data: tableData }),
        { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
            tableInstance;

    return (
        <StyleContextProvider styles={styles}>
            <SectionBasic data={data} sectionWrapper={styles.VersusOthers} />
            <div className={styles.VersusOthers}>
                <div className={styles.VersusOthers__ScrollWrap}>
                    <table {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th
                                            {...column.getHeaderProps()}
                                            className={column.id}
                                        >
                                            {column.render('Header')}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map((row) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => (
                                            <td
                                                {...cell.getCellProps()}
                                                className={cell.column?.id}
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </StyleContextProvider>
    );
};

export default VersusOthers;
