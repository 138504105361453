import { createContext } from 'react';

const empty = () => {};

export const modalContext = createContext({
    modal: null,
    setModal: empty,
});

export const bannerContext = createContext({
    banner: null,
    setBanner: empty,
});
