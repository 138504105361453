import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { ENVIRONMENT } from 'utils/constants';
import useShopify from 'utils/hooks/useShopify';

import {
    CheckIcon as Check,
    CheckBoxIcon as CheckBox,
} from '@viome/vnxt-nucleus';

export default function Selectors({
    baseProduct,
    addOnProduct,
    addOnItem,
    addOnItemAttributes,
    idx,
    styles,
    updateCTA,
}) {
    const addOnItemName = addOnItem.bundleName || addOnItem.name,
        { sku } = addOnItem,
        productField = baseProduct?.fields,
        isSubscription = productField?.shopifySubscriptionPlanProd,
        productShopify =
            (ENVIRONMENT === 'development' || ENVIRONMENT === 'preview'
                ? productField?.shopifyStaging
                : productField?.shopifyProduction) && useShopify(productField),
        addOnProductShopify =
            (ENVIRONMENT === 'development' || ENVIRONMENT === 'preview'
                ? addOnProduct?.shopifyStaging
                : addOnProduct?.shopifyProduction) && useShopify(addOnProduct),
        productPrice = productShopify?.price || 'N/A',
        addOnPrice = addOnProductShopify?.price || 'N/A',
        [active, setActive] = useState(false),
        onClick = () => {
            setActive(!active);
            updateCTA(sku, !active);
            if (!active) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    selector_base_product:
                        productField.bundleName || productField.name,
                    selector_option: addOnItemName,
                    event: 'selectorEvent',
                });
                if (addOnProductShopify) {
                    window.dataLayer.push({
                        viome_ecom_name:
                            addOnProductShopify?.name || addOnProduct?.name,
                        viome_ecom_sku: addOnProduct.sku,
                        viome_ecom_price: addOnPrice,
                        event: 'productPage',
                    });
                }
            }
        },
        router = useRouter(),
        { asPath } = router;

    let selectorAttrs = {};

    if (idx > 0) {
        selectorAttrs = {
            onClick,
            onKeyPress: onClick,
            role: 'button',
            tabIndex: 0,
        };
    }

    useEffect(() => {
        const hashString = asPath.match(/#([^?]+)/),
            queriedProduct = hashString && hashString[1];

        if (queriedProduct === sku || queriedProduct === addOnProduct.sku) {
            onClick();
        }
    }, [asPath]);

    return (
        <div
            className={classNames(
                styles.option,
                idx === 0 ? styles.selected : styles.optional,
                active && styles.active,
                'mt-2',
            )}
            {...selectorAttrs}
        >
            {idx === 0 ? (
                <Check color="#146464" />
            ) : (
                <CheckBox selected={active} />
            )}
            <div>
                <p>{addOnItemName}</p>
                {idx !== 0 && productPrice !== 'N/A' && addOnPrice !== 'N/A' && (
                    <p className={styles.addOnPrice}>
                        <span>
                            {isSubscription
                                ? `+ $${addOnPrice - productPrice}`
                                : `$${addOnPrice}`}
                        </span>{' '}
                        monthly
                    </p>
                )}
                {addOnItemAttributes && (
                    <ul className="mt-1">
                        {addOnItemAttributes.map((attribute) => (
                            <li>{attribute}</li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
}
