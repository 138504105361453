import { useEffect, useState } from 'react';
import Link from 'next/link';
import classNames from 'classnames';
import ConditionalWrapper from 'utils/ConditionalWrapper';
import { useViewport } from 'utils/context/ViewportContextProvider';
import {
    getBackgroundFile,
    getViewportFromWidth,
    cBoolean,
    renderSection,
    applyHashMarkToHexCode,
    parseModules,
} from '@viome/vnxt-nucleus';
import Modules from './Modules';
import Text from './Module/Text';
import RowInlineStyles from './Utils/RowInlineStyles';
import AutoPlayVideo from './Utils/AutoPlayVideo';
import styles from './Row.module.scss';

const columnClasses = {
        sectionSingleColumn: 'single-column',
        sectionMultiColumn: 'multi-column',
    },
    columnStyles = {
        flex: 'flex',
        '40-40': 'fourty-fourty',
        '40-60': 'fourty-sixty',
        '50-50': 'half',
        '50-50 - Row at Desktop': 'half-desktop',
        '50-50 Flex': 'half-flex',
        '60-40': 'sixty-fourty',
        'Dock Right': 'dock-right',
        'Dock Left': 'dock-left',
        'Promotional Banner': 'promo',
    },
    paddingStyles = {
        'Remove Padding - Top/Bottom': 'paddingNone--tb',
        'Remove Padding - Sides': 'paddingNone--sides',
        'Remove Padding - Top': 'paddingNone--top',
        'Remove Padding - Bottom': 'paddingNone--bottom',
        'Remove Padding - Left': 'paddingNone--left',
        'Remove Padding - Right': 'paddingNone--right',
    },
    Row = ({ data, nested }) => {
        const {
                id,
                backgroundColor,
                backgroundImageMedium,
                backgroundImageSmall,
                backgroundImageXl,
                backgroundVideo,
                body,
                bodyMobile = false,
                borderColor,
                columns,
                contentWidth,
                flipAtDesktop,
                fullWidth,
                heights,
                horizontalAlign,
                isBackgroundAttached,
                marginBottom,
                marginTop,
                mode,
                moduleMode,
                modules,
                name,
                padding,
                removePadding,
                renderAtDesktop,
                renderAtMobile,
                renderAtTablet,
                scrollable,
                style,
                url,
                verticalAlign,
                videoRatio,
                wrap,
                __typename,
                sys,
            } = data,
            rowID = __typename + sys.id,
            bodyText = body?.fields,
            moduleItems = modules?.items || parseModules(modules),
            columnClass = columnClasses[__typename],
            columnStyle = style && columnStyles[style],
            darkMode = mode === 'Dark',
            isFlip = cBoolean(flipAtDesktop),
            isModule = cBoolean(moduleMode),
            hasBorder = borderColor,
            hasBackgroundImg =
                backgroundImageSmall ||
                backgroundImageMedium ||
                backgroundImageXl,
            hasBackground = backgroundColor || hasBackgroundImg,
            inlineStyles = {},
            containerStyles = {},
            paddingClasses = [],
            { pixelDensity, width } = useViewport(),
            viewport = getViewportFromWidth(width),
            renderConfig = { renderAtMobile, renderAtTablet, renderAtDesktop },
            showSection = renderSection(renderConfig, width),
            showBody = renderSection(
                {
                    renderAtMobile: true,
                    renderAtTablet: !bodyMobile,
                    renderAtDesktop: !bodyMobile,
                },
                width,
            ),
            videoData = backgroundVideo?.fields.file,
            [bgImage, setbgImage] = useState(null);

        useEffect(() => {
            if (hasBackgroundImg) {
                const imageData =
                        data[`backgroundImage${viewport}`] ||
                        // Select the next biggest size if viewport size isn't available
                        backgroundImageXl ||
                        backgroundImageMedium ||
                        backgroundImageSmall,
                    imageFile = imageData?.fields?.file,
                    parsedURL = getBackgroundFile(
                        imageFile,
                        pixelDensity,
                        viewport,
                    );

                if (parsedURL) {
                    setbgImage(parsedURL);
                }
            }
        }, [width]);

        if (backgroundColor) {
            inlineStyles.background = `${applyHashMarkToHexCode(
                backgroundColor,
            )}`;
        }

        if (borderColor) {
            inlineStyles.borderColor = `${applyHashMarkToHexCode(borderColor)}`;
        }

        if (padding) {
            padding.forEach((paddingOption) => {
                paddingClasses.push(styles[paddingStyles[paddingOption]]);
            });
            if (paddingClasses.length > 0) {
                paddingClasses.push(styles.paddingNone);
            }
        }

        if (bgImage) {
            // Set bg when client is ready
            inlineStyles.backgroundImage = bgImage;
        } else if (hasBackgroundImg) {
            const imageData =
                    // Load the smallest image possible
                    backgroundImageSmall ||
                    backgroundImageMedium ||
                    backgroundImageXl,
                imageFile = imageData?.fields?.file,
                parsedURL = getBackgroundFile(imageFile, 1, 'Small');

            if (parsedURL) {
                setbgImage(parsedURL);
            }
        }

        if (!Number.isNaN(parseInt(contentWidth, 10))) {
            let styleSuffix = '';
            if (!contentWidth.includes('px') && !contentWidth.includes('%')) {
                styleSuffix = 'px';
            }
            containerStyles.maxWidth = `${contentWidth}${styleSuffix}`;
            containerStyles.width = `100%`;
        }

        return (
            showSection && (
                <ConditionalWrapper
                    condition={cBoolean(url)}
                    wrapper={(nodeChildData) => (
                        <Link href={url}>
                            {nodeChildData}
                        </Link>
                    )}
                >
                    {name && name.includes('[Demo]') && nested !== true && (
                        <h1 className={styles.demo}>
                            {name.replace('[Demo] - ', '')}
                        </h1>
                    )}
                    {(heights || videoData) && (
                        <RowInlineStyles
                            heights={heights}
                            rowID={rowID}
                            videoRatio={videoData && (videoRatio || 0.6)}
                        />
                    )}
                    <section
                        id={id}
                        className={classNames(
                            styles.Row,
                            'row',
                            styles[columnClass],
                            (columns || columns === 0) &&
                                styles[`${columnClass}-${columns}`],
                            columnClass,
                            darkMode && `${styles.dark} dark`,
                            fullWidth && styles.full,
                            removePadding && styles.removePadding,
                            paddingClasses,
                            hasBackground && `${styles.bg} row--bg`,
                            hasBackgroundImg && styles['bg--image'],
                            isBackgroundAttached && styles['bg--attached'],
                            marginBottom && styles['margin-bottom'],
                            marginTop && styles['margin-top'],
                            hasBorder && styles.border,
                            rowID,
                            cBoolean(scrollable) && styles.scrollable,
                        )}
                        style={inlineStyles}
                    >
                        {backgroundVideo && (
                            <AutoPlayVideo
                                src={`https:${videoData?.url}`}
                                cls={styles.videoBackground}
                            />
                        )}
                        {bodyText && showBody && (
                            <div className={styles.Row__body}>
                                <Text data={bodyText} />
                            </div>
                        )}
                        <div
                            className={classNames(
                                styles.Row__container,
                                'row__container',
                                isModule && styles.isModule,
                                columnStyle &&
                                    `${columnStyle} ${styles[columnStyle]}`,
                                columns === 2 &&
                                    moduleItems.length % 2 === 1 &&
                                    styles.odd,
                                isFlip && styles.flipAtDesktop,
                                wrap && styles.wrap,
                                horizontalAlign &&
                                    styles[`horizontal-${horizontalAlign}`],
                                verticalAlign && styles[verticalAlign],
                                verticalAlign,
                                style === 'Promotional Banner' &&
                                    heights &&
                                    styles.noPadding,
                            )}
                            style={containerStyles}
                        >
                            <Modules
                                modules={moduleItems}
                                styles={styles}
                                columns={columns}
                                wrap={wrap}
                                darkMode={darkMode}
                            />
                        </div>
                    </section>
                    <hr className={styles.separator} />
                </ConditionalWrapper>
            )
        );
    };

export default Row;
