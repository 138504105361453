import StyleContextProvider from 'utils/context/StyleContextProvider';
import SectionBasic from './SectionBasic';
import styles from './CLIAPrivacy.module.scss';

export default function CLIAPrivacy({ data }) {
    return (
        <StyleContextProvider styles={styles}>
            <SectionBasic data={data} sectionWrapper={styles['CLIA-Privacy']} />
        </StyleContextProvider>
    );
}
