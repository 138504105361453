import classNames from 'classnames';
import StyleContextProvider from 'utils/context/StyleContextProvider';
import SectionBasic from './SectionBasic';
import styles from './Benefits.module.scss';

export default function Benefits({ data }) {
    return (
        <StyleContextProvider styles={styles}>
            <SectionBasic
                data={data}
                sectionWrapper={classNames(
                    styles.benefits,
                    data?.class.indexOf('carousel') > -1 &&
                        styles['benefits--carousel'],
                    data?.class.indexOf('--gi-plan') > -1 &&
                        styles['benefits--gi-plan'],
                    data?.class.indexOf('--border') > -1 &&
                        styles['benefits--border'],
                    data?.class.indexOf('--gut-intelligence') > -1 &&
                        styles['benefits--gi'],
                )}
            />
        </StyleContextProvider>
    );
}
