import StyleContextProvider from 'utils/context/StyleContextProvider';
import SectionBasic from './SectionBasic';
import styles from './Perks.module.scss';

export default function Perks({ data }) {
    return (
        <StyleContextProvider styles={styles}>
            <SectionBasic data={data} moduleWrapper={styles.perks} />
        </StyleContextProvider>
    );
}
