import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import StyleContextProvider from 'utils/context/StyleContextProvider';
import { useViewport } from 'utils/context/ViewportContextProvider';
import { cBoolean } from '@viome/vnxt-nucleus';
import SectionBasic from '../SectionBasic';
import SectionBody from '../SectionBody';
import styles from './Slideshow.module.scss';

export default function Slideshow({ data }) {
    const { autoplay, delay, timeoutDelay, apBreakpoint, clickable } = data,
        { width } = useViewport(),
        [activeSlide, setActiveSlide] = useState(0),
        [autoAdvanceTimeout, setAutoAdvanceTimeout] = useState(true),
        onClick = (idx) => {
            setAutoAdvanceTimeout(false);
            setActiveSlide(idx);
        },
        slideshowProps = {
            autoPlay: cBoolean(autoplay),
            clickable: cBoolean(clickable),
            delay: delay || 2000,
            timeoutDelay: timeoutDelay || 10000,
            apBreakpoint: apBreakpoint || 0,
        };
    useEffect(() => {
        let interval;

        if (width < slideshowProps.apBreakpoint) {
            setAutoAdvanceTimeout(false);
            setActiveSlide(0);
        } else {
            setAutoAdvanceTimeout(true);
        }

        if (slideshowProps.autoPlay) {
            if (autoAdvanceTimeout) {
                interval = setInterval(() => {
                    setActiveSlide(
                        (prevCounter) =>
                            (prevCounter + 1) % data.modules.length,
                    );
                }, slideshowProps.delay);
            } else {
                clearInterval(interval);
                if (width >= slideshowProps.apBreakpoint) {
                    setTimeout(
                        () => setAutoAdvanceTimeout(true),
                        slideshowProps.timeoutDelay,
                    );
                }
            }
        }
        return () => clearInterval(interval);
    }, [autoAdvanceTimeout, width]);

    function moduleParser(modules) {
        return (
            <div className={styles.slides}>
                {modules &&
                    modules.map((module, index) => {
                        const contentType = module.sys.contentType.sys.id,
                            moduleData = module?.fields,
                            settings = moduleData?.settings,
                            moduleID = module.sys.id,
                            bodyBefore =
                                contentType === 'section'
                                    ? moduleData
                                    : {
                                          text: moduleData?.body,
                                          settings,
                                      },
                            bodyAfter = {
                                text: moduleData?.bodyAfter,
                                settings,
                            };

                        let slideAttrs = {};
                        if (slideshowProps.clickable) {
                            slideAttrs = {
                                onClick: () => onClick(index),
                                onKeyPress: onClick,
                                role: 'button',
                                tabIndex: 0,
                            };
                        }
                        return (
                            <div
                                className={classNames(styles.slide, 'slide')}
                                key={moduleID}
                                {...slideAttrs}
                            >
                                <div
                                    className={classNames(
                                        styles.slide__wrapper,
                                        'slide__wrapper',
                                        activeSlide === index && styles.active,
                                    )}
                                >
                                    <SectionBody
                                        data={bodyBefore}
                                        bodyClass={classNames(
                                            styles.before,
                                            'slide__before',
                                        )}
                                    />
                                    <SectionBody
                                        data={bodyAfter}
                                        bodyClass={classNames(
                                            styles.after,
                                            'slide__after',
                                        )}
                                    />
                                </div>
                            </div>
                        );
                    })}
            </div>
        );
    }

    return (
        <StyleContextProvider styles={styles}>
            <SectionBasic data={data}>
                <SectionBody data={data} moduleParser={moduleParser} />
            </SectionBasic>
        </StyleContextProvider>
    );
}
